<section class="wave-section">
    <div class=" py-5 bg-sea-green ">
        <div class="container">
            <div class="row flex-column-reverse flex-md-row ">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56 d-none d-md-block"><span
                            class="text-primary">Elevate Your Brand Online </span>
                            with Social Media Management Services
                        </h1>

                        <p class="my-4 fs-6 text-white text-wrap f-20 justify-text d-none d-md-block">Engage, grow, and
                            connect with your audience effortlessly. Move beyond likes—turn your audience into customers
                            with Social Media Management services that craft impactful strategies tailored to your
                            brand, driving visibility and results.

                        </p>
                        <h1 class="fw-bolder fs-3 d-md-none"><span
                            class="text-primary">Unleash Your Brand’s Potential </span>
                            Comprehensive Social Media Marketing Solutions</h1>
                        <p class=" text-white text-wrap f-20 justify-text d-md-none">At Volmint Technologies, we believe
                            that effective social media marketing is the key to unlocking your brand's full potential.
                            Our comprehensive SMM services cover every aspect of digital marketing, ensuring that your
                            brand gains enhanced visibility and recognition across all platforms.
                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">Get Started <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  py-5 d-flex ">
                    <div
                        class="py-5 position-relative d-flex justify-content-md-end justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/social-media-marketing/info-social-media.png"
                             alt="Image"
                             class="img-fluid border-radius-10 d-none d-md-block w-75"/>
                        <img src="assets/img/landing-new/social-media-marketing/info-social-media.png"
                             alt="Image"
                             class="img-fluid w-50 mx-auto d-md-none "/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wave">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="white" fill-opacity="1"
                  d="M0,32L17.1,53.3C34.3,75,69,117,103,144C137.1,171,171,181,206,197.3C240,213,274,235,309,224C342.9,213,377,171,411,154.7C445.7,139,480,149,514,138.7C548.6,128,583,96,617,69.3C651.4,43,686,21,720,32C754.3,43,789,85,823,128C857.1,171,891,213,926,213.3C960,213,994,171,1029,154.7C1062.9,139,1097,149,1131,144C1165.7,139,1200,117,1234,122.7C1268.6,128,1303,160,1337,192C1371.4,224,1406,256,1423,272L1440,288L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path>
        </svg>
    </div>
</section>
<section class="" style="overflow-x:hidden;">
    <div class="container pb-4">
        <div class="row g-5 justify-content-center">
            <div class=" align-self-center">
                <div class=" p-3 md-mt-5 h-100">
                    <h2 class="text-primary md-mt-5 fw-bold">Achieve your business goals with Volmint
                    </h2>
                    <div style="height:10px;" class="rounded border w-25 bg-gradient"></div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center">
                <div
                    class="position-relative  pink-box p-3 text-center d-flex align-items-center justify-content-center  ">
                    <div>
                        <img src="assets/img/landing-new/seo/accept.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class=""> Understanding Your Brand </span>
                        </h3>
                        <p class="text-secondary">
                            We begin by thoroughly examining your brand’s vision, target audience, and business goals.
                            This deep analysis helps us craft a tailored roadmap that aligns with your objectives,
                            values, and growth aspirations.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center">
                <div
                    class="position-relative blue-box p-3 text-center d-flex align-items-center justify-content-center ">
                    <div>
                        <img src="assets/img/landing-new/seo/planning.png" alt="">

                        <h3 class="fs-5 mt-3 fw-bolder">
                    <span
                        class="">Planning and Strategy  </span></h3>
                        <p class="text-secondary">Our experts craft a customized strategy that outlines clear
                            objectives, key performance indicators, content themes, and timelines. This ensures every
                            step is purpose-driven, measurable, and aligned with your brand’s unique identity.
                        </p>
                    </div>

                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative p-3 text-center d-flex align-items-center justify-content-center orange-box">
                    <div>
                        <img src="assets/img/landing-new/seo/content.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">Content Creation</span></h3>
                        <p class="text-secondary"> From compelling copywriting to stunning visuals and videos, we design
                            engaging, high-quality content that resonates with your audience, reflects your brand
                            identity, and drives meaningful results while fostering stronger connections.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative p-3 text-center d-flex align-items-center justify-content-center green-box">
                    <div>
                        <img src="assets/img/landing-new/seo/execution.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">  Execution </span></h3>
                        <p class="text-secondary">We strategically schedule and publish posts, ensuring maximum reach
                            and consistent visibility across all platforms. Our team ensures timely delivery, brand
                            alignment, and seamless execution for a strong online presence.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative yellow-box p-3 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <img src="assets/img/landing-new/seo/monitoring.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">Engagement and Monitoring  </span></h3>
                        <p class="text-secondary">
                            We actively manage your profiles, respond promptly to queries, address customer concerns,
                            and foster meaningful interactions. Our team monitors activity, analyzes trends, and builds
                            trust and loyalty.

                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative red-box p-3 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <img src="assets/img/landing-new/seo/opitmize.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">Review and Optimize  </span></h3>
                        <p class="text-secondary">
                            With an analytics-driven approach, we assess campaign performance, identify gaps, and
                            uncover opportunities for improvement. By analyzing data trends, and audience behavior, keep
                            your brand ahead of the competition.

                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<div class="container-fluid pt-4">
    <div class="container rounded-5 mt-5">
        <div class="row ">
            <div class="col-md-5 col-12 align-self-md-center text-center">
                <img src="assets/img/landing-new/seo/business-growth-seo.png" alt="" class="img-fluid">
            </div>
            <div class="col-md-7 col-12 py-5 px-4">
                <h2 class="text-start fs-1 fw-bolder">
                    <span class="text-primary">About Volmint’s Social Media Management Services
</span></h2>
                <p align="justify" class="text-secondary ">Social media has reshaped how businesses connect with
                    customers, driving
                    engagement and building trust. At Volmint, we don’t just manage your profiles; we transform them
                    into powerful tools for growth. We believe that a strong social media marketing strategy is one of
                    the important factors for your brand’s full potential. Our comprehensive SMM services cover every
                    aspect of digital marketing, ensuring your brand gains the visibility and recognition it deserves
                    across all platforms.
                </p>
                <p align="justify" class="text-secondary">
                    From precise advertising that reaches your ideal audience to strategies that boost customer
                    engagement, we tailor our approach to fit your unique goals. With valuable insights and analytics,
                    we help you make data-driven decisions that fuel growth.

                </p>
                <p align="justify" class="text-secondary">
                    Our team of highly professional experts creates blueprints that align with your goals, deliver
                    quality content, and optimize every post for success. Whether you’re a startup or an established
                    brand, we’re here to make social media work for you. Let us elevate your online presence and
                    transform your brand into a powerful force in the digital world.
                </p>
            </div>

        </div>
    </div>
</div>


<div class="container-fluid my-md-5">
    <div class="container ">
        <div class="row g-4 pb-4 justify-content-center mx-auto">
            <div class=" align-self-center pt-md-5 pb-4">
                <h2 class="my-2 fs-1 fw-bolder">
                     <span
                         class="text-primary">Expand Your Digital Reach with Services we Offer</span>
                </h2>
                <p class="text-secondary">As a well-known social media management consultant, Volmint Technologies
                    provides end-to-end solutions that transform your online visibility and engagement. Following
                    in-depth research of your business and requirements, we craft tailored strategies to amplify your
                    brand’s reach, enhance customer engagement, and drive meaningful results across all platforms.
                </p>
            </div>
            <div class="shadow row rounded py-4">
                <div class="col-md-6 py-0 border-end border-bottom">
                    <div class="h-100 py-2">
                        <div class="d-flex  py-4">
                            <div class="bg-primary py-0 mt-3 rounded"
                                 style="transform:rotate(90deg); width: 55px ; height: 10px"></div>
                            <h4 class="fw-bold mt-2 text-center ">Strategy Development</h4>
                        </div>
                        <div class="px-5">
                            <p class="text-secondary"> We design customized social media strategies that align with your
                                business objectives, target audience, and industry trends to maximize results.  </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="h-100 py-2">
                        <div class="d-flex py-4">
                            <div class="bg-primary py-0 mt-3 rounded"
                                 style="transform:rotate(90deg); width: 55px ; height: 10px"></div>
                            <h4 class="fw-bold mt-2 text-center ">Content Creation </h4>
                        </div>
                        <div class="px-5">
                            <p class="text-secondary"> Our creative team delivers engaging content—including posts,
                                videos,
                                infographics, and stories—that captures attention and reflects your brand identity.
                        </div>
                    </div>
                </div>
                <div class="col-md-6 border-end border-bottom">
                    <div class="h-100 py-2">
                        <div class="d-flex py-4">
                            <div class="bg-primary py-0 mt-3 rounded"
                                 style="transform:rotate(90deg); width: 55px ; height: 10px"></div>
                            <h4 class="fw-bold mt-2 text-center ">Social Media Posting & Scheduling</h4>
                        </div>
                        <div class="px-5">
                            <p class="text-secondary">We ensure your content reaches the right audience at the right
                                time with a well-planned content calendar tailored to each platform.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="h-100 py-2">
                        <div class="d-flex py-4">
                            <div class="bg-primary py-0 mt-3 rounded"
                                 style="transform:rotate(90deg); width: 55px ; height: 10px"></div>
                            <h4 class="fw-bold mt-2 text-center ">Community Management
                            </h4>
                        </div>
                        <div class="px-5">
                            <p class="text-secondary"> We foster meaningful conversations by responding to comments,
                                messages, and reviews, ensuring your audience feels valued and connected.
                        </div>
                    </div>
                </div>
                <div class="col-md-6 border-end border-bottom ">
                    <div class="h-100 py-2">
                        <div class="d-flex py-4">
                            <div class="bg-primary py-0 mt-3 rounded"
                                 style="transform:rotate(90deg); width: 55px ; height: 10px"></div>
                            <h4 class="fw-bold mt-2 text-center ">Paid Advertising
                            </h4>
                        </div>
                        <div class="px-5">
                            <p class="text-secondary">Our targeted ad campaigns on platforms like Facebook, Instagram,
                                and LinkedIn drive traffic, leads, and conversions cost-effectively.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 border-bottom">
                    <div class="h-100 py-2">
                        <div class="d-flex py-4">
                            <div class="bg-primary py-0 mt-3 rounded"
                                 style="transform:rotate(90deg); width: 55px ; height: 10px"></div>
                            <h4 class="fw-bold mt-2 text-center ">Analytics & Reporting</h4>
                        </div>
                        <div class="px-5">
                            <p class="text-secondary">We provide detailed reports with actionable insights, helping you
                                measure performance, understand trends, and refine your social media strategy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<div class="container-fluid bg-gradient">
    <div class="container py-5">
        <h2 class="text-white fw-bold justify-text">Why SMM is Essential for Your Business?
        </h2>
        <p class="text-secondary text-white justify-text mt-3">Social media management is key for your business’s growth
            and
            visibility. A strong social media presence helps you stay relevant, attract new customers, increase profit,
            and build trust. Social media strategies are crucial for connecting with your audience, building brand
            awareness, and driving engagement. <br>
            Without the helping hand of a social media management company, you could be missing opportunities to boost
            your brand and stand out in today’s competitive market. Let’s look at the challenges businesses face when
            ignoring SMM and how Volmint Technologies can help boost your success.
        </p>
        <div class="row mx-md-1 align-items-center border-bottom py-4" *ngFor="let item of whyChoose; let i = index">
            <div class="fs-1 col-md-5 col-12 rounded-circle   fw-bold text-white ">
                <h4 class="text-white  fw-bold">{{ item.header }}</h4>
            </div>
            <div class="my-2 col-md-7 col-12">

                <p class="text-white justify-text">{{
                        item.text
                    }}</p>
            </div>

        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container pt-md-5 ">
        <div class="row justify-content-center py-5">
            <h2 class=" fs-1 fw-bolder  text-center">
                <span class="text-primary"> Social Media Management</span> Vs. Social Media Marketing: Major Differences
            </h2>
            <p class="text-secondary text-center">While we often overlap, Social Media Management (SMM) and Social Media
                Marketing
                serve distinct purposes. Here’s how they differ:</p>
            <div
                class="col-md-6  d-flex justify-content-md-end pe-md-5 justify-content-center align-self-center pe-0 py-3  position-relative ">
                <div class="p-0 shadow bg-white w-75 seo py-5">
                    <div
                        class="flag-div d-flex justify-content-between align-items-center ps-5 fs-1 fw-bold text-white">
                        <div class="py-3 ps-3">
                            <p class="title fs-3 text-white me-5">SMM</p>
                        </div>
                        <div class="py-3 pe-2">
                            <i class="fa fa-thumbs-up fs-4 me-3"></i>
                        </div>
                    </div>
                    <h4 class="fw-bold mx-4 t">Social Media Management - Smm
                    </h4>
                    <ul class="list-unstyled px-3">
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                SMM focuses on maintaining your brand’s online presence

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Focus on consistent posting and engagement

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                SMM fosters organic relationships with followers

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Management typically involves minimal costs


                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Management builds long-term loyalty and trust


                            </p>
                        </li>
                    </ul>

                </div>
            </div>
            <div
                class="col-md-6 d-flex align-self-center justify-content-center justify-content-md-start ps-md-5 ps-0 py-3  position-relative ">
                <div class="p-0 shadow bg-white w-75 ppc py-5">
                    <div
                        class="flag-div d-flex justify-content-between align-items-center pe-2 fs-1 fw-bold text-white">
                        <div class="py-3 pe-2">
                            <p class="title fs-3 text-white me-5">SMM</p>
                        </div>
                        <div class="py-3">
                            <i class="fa fa-thumbs-up fs-4 me-5"></i>
                        </div>
                    </div>
                    <h4 class="fw-bold mx-4">Social Media Marketing
                    </h4>
                    <ul class="list-unstyled px-3">
                        <li class="d-flex p-1 align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Social Media Marketing drives campaigns to drive sales or leads
                            </p>
                        </li>
                        <li class="d-flex p-1 align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Emphasizes promotional content tailored for campaigns
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Uses ads to reach and convert a broader audience
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Marketing requires investment in paid campaigns
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Marketing focuses on immediate results and measurable ROI
                            </p>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid ">
    <div class="container">
        <div class="row py-3">
            <div class="col-12 col-md-6 align-self-center">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image"
                         class="img-fluid w-50"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="text-primary fw-bold">Email: <a href="mailto:support@volmint.com"
                                                              class="text-decoration-underline text-primary">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4 ">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11 text-primary">
How is content created for social media marketing?                                      </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Social media marketing content is crafted based on the target audience, business goals,
                                and platform requirements. A social media management company or consultant often
                                conducts research, designs engaging visuals, and writes compelling captions to resonate
                                with your audience while optimizing posts for visibility and engagement.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11 text-primary">
How much does social media management cost?

                                 </div>
                             </div>
                            </span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The cost for social media management services may vary as per the business recruitment
                                and goals. Usually, the cost for basic to advanced SMM may range between INR 10,000 -
                                70,000 PM.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11 text-primary">
How long does it take to see results from social media marketing?

                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Results can vary depending on your goals and strategy. Organic growth often takes
                                several months, while paid ads can deliver quicker results. A social media management
                                consultant can guide you on the timeline based on your specific business needs.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false"
                                    aria-controls="collapseFour">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11 text-primary">
Is it better to focus on organic growth or paid social media ads?
                                     </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Both approaches have value. Organic growth builds trust and long-term engagement, while
                                paid ads drive immediate visibility and conversions. A balanced strategy crafted by a
                                social media management company can help you achieve the best results.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false"
                                    aria-controls="collapseFive">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11 text-primary">
How do I choose the right social media strategy for my business?

                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The right strategy depends on your target audience, industry, and goals. Consulting with
                                a social media management agency or expert can help you analyze your needs, identify key
                                platforms, and create a customized plan for success.


                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 p-3 p-lg-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png') ; background-size: cover">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to Take Your Business Growth to the Next Level?
            </div>
            <div class="col-md-6 align-self-center col-12 text-md-end">
                <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                        (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
