<!-- Start Topbar Wrap Area -->
<div
    class="topbar-wrap-area d-none"
    [ngClass]="{'d-block': router?.url === '/home-6' || router?.url === '/home-7'}"
>
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-3 col-md-3">
                <div class="topbar-content">
                    <span>Welcome to Sparsity. Need Help? <a routerLink="/contact">Contact Us</a></span>
                </div>
            </div>
            <div class="col-lg-9 col-md-9">
                <ul class="topbar-action-list">
                    <li><i class="fa-solid fa-location-pin"></i> 2750 Quadra Street Victoria, Canada</li>
                    <li>
                        <i class="fa-solid fa-phone"></i> <a href="tel:44587154756">+44 587 154756</a>
                    </li>
                    <li>
                        <i class="fa-solid fa-envelope"></i> <a href="mailto:sparsity@gmail.com">sparsity@gmail.com</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Topbar Wrap Area -->

<!-- Start Navbar Area -->
<div
    class="{{navClass}}"
    [ngClass]="{'p-relative': router?.url === '/home-5' || router?.url === '/home-8'}"
>
    <div class="volmint-nav">
        <div class="{{navContainer}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand d-lg-none" routerLink="/">
                    <img src="{{logo}}" class="main-logo" alt="logo">
                    <img src="assets/img/white-volmint-logo.png" class="optional-logo" alt="logo">
                </a>
                <a class="navbar-brand d-none d-lg-block" routerLink="/">
                    <img src="{{logo}}" class="main-logo" alt="logo" style="height: 75px;">
                    <img src="assets/img/white-volmint-logo.png" class="optional-logo" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mx-auto">
                        <li class="nav-item color-white">
                            <a [routerLink]="['/about']" class="nav-link fs-5 fw-bold" [ngClass]="listClass"
                               routerLinkActive="active-menu">About Us</a>
                        </li>
                         <li class="nav-item color-white">
                             <a [routerLink]="['/projects']" class="nav-link fs-5 fw-bold" [ngClass]="listClass"
                                routerLinkActive="active-menu">Projects</a>
                         </li>
                        <li class="nav-item color-white">
                            <a class="fs-5 nav-link fs-5 fw-bold" role="button" [ngClass]="listClass" (click)="toggleMenu()"
                               routerLinkActive="active-menu">Products
                                <i [ngClass]="{'fa-angle-down': !isMenuOpen, 'fa-angle-up': isMenuOpen}"
                                   class="fa fs-6 ms-2"></i>
                            </a>
                        </li>
                        <div class="mega-menu d-flex justify-content-center p-3 d-none d-md-flex" [ngClass]="listClass"
                             [class.open]="isMenuOpen">
                            <div class="p-5 mega-menu-left-div w-25">
                                <img src="assets/img/v001 WHITE TEXT LOGO-01.png" *ngIf="!selectedImage"
                                     class="main-logo" alt="logo">
                                <img [src]="selectedImage" class="main-logo w-25" *ngIf="selectedImage" alt="logo">

                                <p class="text-white fs-6" *ngIf="!selectedText">Volmint is an IT solutions, cloud
                                    infrastructure, consulting
                                    firm.</p>
                                <p class="text-white fs-6" *ngIf="selectedText">{{ selectedText }}</p>
                            </div>
                            <div class="px-5">
                                <ul class="d-flex justify-content-between flex-wrap">
                                    <li class="w-25 my-3 d-flex ">
                                        <a [routerLink]="['/enroll']" (click)="closeMenu()"
                                           (mouseover)="changeContent('assets/img/project/project-logo/enroll.png', 'Enroll is the premier solution for managing admissions, offering streamlined processes and enhanced efficiency for handling\n'+
'                            student applications and enrollment.')"
                                           class="nav-link d-flex align-items-center">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/project/project-logo/enroll.png"
                                                     class="product-logo"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mt-3">Enroll<br/>
                                                <span class="text-secondary fs-6">Admission Management System</span>
                                            </p>
                                        </a>
                                    </li>
                                    <li class="w-25 my-3"
                                        (mouseover)="changeContent('assets/img/project/project-logo/argus.png', 'Argus is an advanced platform that provides seamless and secure management of online proctoring, ensuring integrity and\n'+
'                            efficiency in virtual assessments.')">
                                        <a [routerLink]="['/argus']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/project/project-logo/argus.png"
                                                     class="product-logo"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mt-3">Argus<br/>
                                                <span class="text-secondary fs-6">Exam Proctoring System</span></p>
                                        </a>
                                    </li>
                                    <li class="w-25 my-3"
                                        (mouseover)="changeContent('assets/img/project/project-logo/ums.png', 'Volmint\'s University Management System (UMS) streamlines university operations, including attendance management, admission tracking, examination processes, and financial integration, with precision and efficiency.')">
                                        <a [routerLink]="['/ums']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/project/project-logo/ums.png" class="product-logo"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mt-3">UMS<br/>
                                                <span class="text-secondary fs-6">University Management System</span>
                                            </p>
                                        </a>
                                    </li>
                                    <li class="w-25 my-3"
                                        (mouseover)="changeContent('assets/img/project/project-logo/cms-logo.png', 'A College Management System (CMS) is a cloud-based software solution that optimizes administrative and academic functions in educational institutions, catering to faculty, learners, parents, and other staff.')">
                                        <a [routerLink]="['/cms']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/project/project-logo/cms-logo.png"
                                                     class="product-logo"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mt-3">CMS<br/>
                                                <span class="text-secondary fs-6">College Management System</span></p>
                                        </a>
                                    </li>
                                    <li class="w-25 my-3"
                                        (mouseover)="changeContent('assets/img/project/project-logo/cgms-no-bg.png', 'The College Group Management System (CGMS) is a software that enhances collaboration and engagement among students and faculty in campus groups, improving communication and coordination.')">
                                        <a [routerLink]="['/cgms']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/project/project-logo/cgms-no-bg.png"
                                                     class="product-logo"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mt-3">CGMS<br/>
                                                <span
                                                    class="text-secondary fs-6">College Group Management System</span>
                                            </p>
                                        </a></li>
                                    <li class="w-25 my-3"
                                        (mouseover)="changeContent('assets/img/project/project-logo/ims.png', 'Volmint\'s Institute Management System streamlines administrative tasks in educational institutions, automating routine processes like report generation and meeting scheduling.')">
                                        <a [routerLink]="['/ims']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/project/project-logo/ims.png"
                                                     class="h-50 w-25"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mt-3">IMS<br/>
                                                <span class="text-secondary fs-6">Institute Management System</span>
                                            </p>
                                        </a></li>
                                    <li class="w-25 my-3"
                                        (mouseover)="changeContent('assets/img/project/project-logo/lms-no-bg.png', 'The cloud-based Learning Management System enhances academic management efficiency by facilitating e-learning, improving teacher-student collaboration, administration, and tracking of online courses.')">
                                        <a [routerLink]="['/lms']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/project/project-logo/lms-no-bg.png"
                                                     class="h-75"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mt-3">LMS<br/>
                                                <span class="text-secondary fs-6">Learning Management System</span></p>
                                        </a></li>
                                    <li class="w-25 my-3"
                                        (mouseover)="changeContent('assets/img/project/project-logo/eclinic-logo.png', 'E-Clinic Solution simplifies clinic management, saving time on administrative tasks for healthcare chains and practitioners, improving patient satisfaction, and expanding clientele.')">
                                        <a [routerLink]="['/eclinic']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/project/project-logo/eclinic-logo.png"
                                                     class="product-logo"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mt-3">E-clinic<br/>
                                                <span
                                                    class="text-secondary fs-6">Digital Clinic Management System</span>
                                            </p>
                                        </a></li>
                                    <li class="w-25 my-3"
                                        (mouseover)="changeContent('assets/img/project/project-logo/acricent-logo.png', 'Acricent is a comprehensive tool designed to simplify your income tax filing with efficiency and accuracy.')">
                                        <a [routerLink]="['/acricent' +
                                         '']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/project/project-logo/acricent-logo.png"
                                                     class="product-logo"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mt-3">Acricent<br/>
                                                <span
                                                    class="text-secondary fs-6">Income Tax Return Management System</span>
                                            </p>
                                        </a></li>
                                    <li class="w-25 my-3"
                                        (mouseover)="changeContent('assets/img/project/project-logo/vcalling-white.png', 'Elevate your sales, support, and business teams with V-Calling from Volmint Technologies! Experience seamless global connectivity with crystal-clear HQ voice, AI-powered IVR, and a cutting-edge omnichannel cloud contact center.')">
                                        <a [routerLink]="['/vcalling' +
                                         '']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/project/project-logo/v-calling-black.png"
                                                     class="product-logo"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mt-3">V-Calling<br/>
                                                <span
                                                    class="text-secondary fs-6">Video Conferencing System</span>
                                            </p>
                                        </a></li>
                                </ul>
                                <hr>
                                <div class="d-flex justify-content-between">
                                    <div class="w-25 d-flex justify-content-between">
                                        <a href="https://www.linkedin.com/company/volmint-technologies/" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-linkedin"></i></span></a>
                                        <a href=" https://twitter.com/TechVolmint" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-twitter"></i></span></a>
                                        <a href="https://www.instagram.com/volminttechnologies/" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-instagram"></i></span></a>
                                        <a href="https://www.facebook.com/VolmintTechnologies" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-facebook-f"></i></span></a>
                                        <a href="https://www.youtube.com/channel/UChR9FpeL0Jm1a4ks-rOowPA" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-youtube"></i></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Mega Menu for Small Screens -->
                        <div class="mega-menu-small d-flex flex-column p-3 d-md-none"
                             [ngClass]="{'d-block': isMenuOpen, 'd-none': !isMenuOpen}">
                            <ul class="list-unstyled">
                                <li class="d-flex align-items-center mb-3">
                                    <a href="https://enroll.volmint.com/" target="_blank"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 product-logo-bg border me-3">
                                            <img src="assets/img/project/project-logo/enroll.png" class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <p class="fs-5 mb-0">Enroll<br/><span class="text-secondary fs-6">Admission Management System</span>
                                        </p>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center mb-3">
                                    <a [routerLink]="['/argus']" (click)="closeMenu()"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 product-logo-bg border me-3">
                                            <img src="assets/img/project/project-logo/argus.png" class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <p class="fs-5 mb-0">Argus<br/><span class="text-secondary fs-6">Exam Proctoring System</span>
                                        </p>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center mb-3">
                                    <a [routerLink]="['/ums']" (click)="closeMenu()"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 product-logo-bg border me-3">
                                            <img src="assets/img/project/project-logo/ums.png" class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <p class="fs-5 mb-0">UMS<br/><span class="text-secondary fs-6">University Management System</span>
                                        </p>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center mb-3">
                                    <a [routerLink]="['/cms']" (click)="closeMenu()"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 product-logo-bg border me-3">
                                            <img src="assets/img/project/project-logo/cms-logo.png"
                                                 class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <p class="fs-5 mb-0">CMS<br/><span class="text-secondary fs-6">College Management System</span>
                                        </p>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center mb-3">
                                    <a [routerLink]="['/cgms']" (click)="closeMenu()"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 product-logo-bg border me-3">
                                            <img src="assets/img/project/project-logo/cgms-no-bg.png" class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <p class="fs-5 mb-0">CGMS<br/><span class="text-secondary fs-6">College Group Management System</span>
                                        </p>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center mb-3">
                                    <a [routerLink]="['/ims']" (click)="closeMenu()"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 product-logo-bg border me-3">
                                            <img src="assets/img/project/project-logo/ims.png" class="h-75 w-50"
                                                 alt="Image">
                                        </div>
                                        <p class="fs-5 mb-0">IMS<br/><span class="text-secondary fs-6">Institute Management System</span>
                                        </p>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center mb-3">
                                    <a [routerLink]="['/lms']" (click)="closeMenu()"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 product-logo-bg border me-3">
                                            <img src="assets/img/project/project-logo/lms-no-bg.png" class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <p class="fs-5 mb-0">LMS<br/><span class="text-secondary fs-6">Learning Management System</span>
                                        </p>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center mb-3">
                                    <a [routerLink]="['/eclinic']" (click)="closeMenu()"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 product-logo-bg border me-3">
                                            <img src="assets/img/project/project-logo/eclinic-logo.png"
                                                 class="product-logo" alt="Image">
                                        </div>
                                        <p class="fs-5 mb-0">E-clinic<br/><span class="text-secondary fs-6">Digital Clinic Management System</span>
                                        </p>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center mb-3">
                                    <a [routerLink]="['/acricent']" (click)="closeMenu()"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 product-logo-bg border me-3">
                                            <img src="assets/img/project/project-logo/acricent-logo.png"
                                                 class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <p class="fs-5 mb-0">Acricent<br/><span class="text-secondary fs-6">Income Tax Return Management System</span>
                                        </p>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center mb-3">
                                    <a [routerLink]="['/vcalling']" (click)="closeMenu()"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 product-logo-bg border me-3">
                                            <img src="assets/img/project/project-logo/v-calling-black.png"
                                                 class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <p class="fs-5 mb-0">V-Calling<br/><span class="text-secondary fs-6">Video Conferencing System</span>
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <li class="nav-item color-white">
                            <a [routerLink]="['/careers']" class="nav-link fs-5 fw-bold" [ngClass]="listClass"
                               routerLinkActive="active-menu">Careers</a>
                        </li>
                    </ul>
                    <div class="text-end">
                        <button class="px-5 py-3 landing-button border-0 fs-6 nav-link color-white fw-bold"
                                routerLink="/contact" routerLinkActive="active">Contact Us <i class="fa fa-arrow-right"
                                                                                              aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
