<div class="container-fluid bg mt-5">
    <div class="container mt-5 pt-5">
        <div class="row g-5">
            <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                <div>
                    <h1 class="fw-bolder font-56"> Let
                        <span class="brand-primary-text-color">Acricent</span>
                        Handle Your ITR, You Focused on Your Growing Business
                    </h1>
                    <p class="my-4 fs-6 text-secondary f-20 justify-text">Acricent is the fastest-growing platform for all Tax
                        services
                        and solutions. As one of the leading financial service software platforms, we are committed to
                        saving every penny you deserve.
                    </p>
                    <button class="btn bg-white border-0 fw-bold px-5 rounded-0 hover-effect" (click)="openModal()">
                        View Demo <i class="ms-3 fa-solid fa-arrow-right"></i>
                    </button>
                    <ng-template #contactFormModal>
                        <app-contact-form [isModalOpen]="isModalOpen" (closeModal)="closeModal()"></app-contact-form>
                    </ng-template>
                    <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                        <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-6  d-flex justify-content-end py-5">
                <div class="card banner-bg-color md:mr-0 py-5">
                    <img src="assets/img/landing-new/acricent/acricent-banner.gif" class="w-75 mx-auto" alt="Image">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <h1 class="text-center mt-5 fw-bolder">Our Featured Services</h1>
        <p class="text-center text-secondary justify-text">Real innovations and a positive customer experience are the heart
            of
            successful
            communication. No fake products and services.</p>
        <div class="row">
            <div class="col-md-4 col-12 d-flex justify-content-center" *ngFor="let service of services">
                <div class="box shadow-lg">
                    <i class="{{service.icon}} fs-1 mt-4" [ngStyle]="{'color': service.color}" aria-hidden="true"></i>
                    <p class="heading fw-bold fs-3">{{ service.heading }}</p>
                    <p class="description text-secondary justify-text">{{ service.description }}</p>
                    <ul class="list text-secondary mt-4 text-start">
                        <li *ngFor="let item of service.listItems" class="justify-text">
                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid"> {{ item }}
                        </li>
                    </ul>
       <!--             <button type="submit" class="bg-color-blue border-0 color-white px-5 py-2 fs-6 mt-4 rounded-3">
                        {{ service.button }}
                    </button>-->
                </div>
            </div>
        </div>
    </div>
</div>

<!--<section class="plans">
    <div class="container-fluid mt-5">
        <div class="container">
            <h1 class=" text-center mt-5 fw-bolder">Plans We Offer</h1>
            <p class="text-center text-secondary fw-bold justify-text">We value money! our services are affordable and
                customizable
                as per the needs and requirements of the client. We believe in complete transparency and do
                not
                follow any hidden charges.</p>
            <div class="d-flex justify-content-center">
                <div class="row w-md-75 g-5">
                    <div class="col-md-6 col-12 d-flex justify-content-center" *ngFor="let plan of plans">
                        <div class="card rounded-3 shadow-lg border-0">
                            <div class="card-header color-white fs-4 fw-bold text-center"
                                 [ngStyle]="{'background-color': plan.bg}">{{ plan.heading }}
                                <p class="color-white fs-6 fw-bold">{{ plan.description }}</p>
                            </div>
                            <div class="card-content p-3">
                                <p class="price text-center"><i class="fa fa-inr"></i> <span
                                    class="fs-1 fw-bold"> {{ plan.price }}</span></p>
                                <hr>
                                <ul class="list text-secondary mt-4 text-start">
                                    <li *ngFor="let item of plan.listItems">
                                        <div class="row w-100">
                                            <div class="col-10 fs-6 fw-bold">
                                                {{ item }}
                                            </div>
                                            <div class="col-2 text-end">
                                                <i class="fas fa-check green"></i>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <hr>
                                <p class="text-center fs-2 fw-bold my-4" (click)="goTo('/contact')">Get Started</p>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <button
                            class="color-white plans-button border px-4 rounded-0 my-4 w-25 py-3 rounded-3 fs-5 fw-bold"
                            (click)="goTo('/contact')">
                            More Details
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<section class="easySteps">
    <div class="container-fluid mt-5 acricent-bg-color py-4">
        <div class="container">
            <h1 class="text-center mt-5 fw-bolder">3 Easy Step</h1>
            <p class="text-center text-secondary justify-text">File income tax easily in a 3 steps: gather your financial
                documents,
                organize deductions, fill out the required forms accurately, and submit electronically or by mail. Stay
                stress-free while fulfilling your tax obligations efficiently.
            </p>
            <div class="row w-md-75 g-5">
                <div class="col-md-4 col-12 d-flex justify-content-center" *ngFor="let item of easySteps">
                    <div class="card rounded-3 shadow-lg border-0 w-100 p-3 mb-5">
                        <div class="overlay">
                  <!--          <p class="coming-soon">Coming Soon <i
                                class="ms-3 fa-solid fa-arrow-right"></i></p>-->
                        </div>
                        <div class=" col-2 icon-wrapper" [ngStyle]="{'background-color': item.iconBackgroundColor}">
                            <i class="{{item.icon}} ms-4" [ngStyle]="{'color': item.color}" aria-hidden="true"></i>
                        </div>
                        <div class="card-content p-3">
                            <h1 class="fs-3 fw-bold">{{ item.heading }}</h1>
                            <p class="fs-6 text-wrap justify-text">{{ item.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="trust">
    <div class="container-fluid mt-5">
        <div class="container">
            <h1 class="text-center mt-5 fw-bolder">Committed to building Trust</h1>
            <p class="text-center acricent-color justify-text">ENSURING UTMOST SECURITY IN EVERY STEP. SAFEGUARDING YOUR
                SENSITIVE
                FINANCIAL INFORMATION AND PROTECTING YOUR PRIVACY THROUGHOUT THE ENTIRE TAX FILING PROCESS.
            </p>
            <div class="row g-5 mt-2">
                <div class="col-md-3 col-12" *ngFor="let item of trust">
                    <div class="card rounded-3 shadow-lg border-0 w-100 p-4">
                        <div class="card-content p-3 text-center">
                            <img src="{{item.image}}" alt="Image" class="img-fluid w-75"/>
                            <h1 class="fs-3 fw-bold">{{ item.heading }}</h1>
                            <p class="fs-6 text-wrap text-secondary">{{ item.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="customer">
    <div class="container-fluid mt-5">
        <div class="container">
            <h1 class="text-center mt-5 fw-bolder">We always try to understand customers expectation</h1>
            <div class="row text-center mt-4">
                <div class="col-12 ">
                    <button class="btn px-5 py-4 rounded-0 color-white mx-2 acricent-dark" (click)="changeContent(1)">
                        Support
                    </button>
                    <button class="btn px-5 py-4 rounded-0 color-white mx-2 acricent-dark" (click)="changeContent(2)">
                        Reliability
                    </button>
                    <button class="btn px-5 py-4 rounded-0 color-white mx-2 acricent-dark" (click)="changeContent(3)">
                        Affordable
                    </button>
                    <button class="btn px-5 py-4 rounded-0 color-white mx-2 acricent-dark" (click)="changeContent(4)">
                        Savings
                    </button>
                </div>
            </div>
            <div class="row mt-5 g-5">
                <div class="col-md-6 col-12">
                    <img [src]="currentImage" alt="Image" class="img-fluid"/>
                </div>
                <div class="col-md-6 col-12">
                    <h2 class="fs-1 fw-bolder">{{ currentHeading }}</h2>
                    <p class="my-5 fs-6 text-wrap text-secondary justify-text">{{ currentDescription }}</p>
                    <div class="row g-3 mt-2">
                        <div class="col-12 px-0 my-3" *ngFor="let item of currentOfferings">
                            <span>
                                <img src="assets/img/project/check.png" alt="Image" class="img-fluid">
                            </span>
                            <span class="ms-2 text-secondary justify-text">
                                {{ item.text }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<section class="reviews">
    <div class="container-fluid mt-5 acricent-dark py-5">
        <div class="container">
            <h1 class="text-center mt-5 fw-bolder color-white">"Glowing Reviews Shared by Our Satisfied Clients"</h1>
            <div class="row g-5 mt-2">
                <div class="col-md-3 col-12" *ngFor="let item of reviews">
                    <div class="card rounded-3 shadow-lg border-0 w-100 p-3 mb-5">
                        <div class="card-content p-3 text-center mt-4">
                            <p class="fs-6 text-wrap text-secondary fw-bold justify-text">{{ item.description }}</p>
                            <div class="row mt-4 ">
                                <div class="col-4">
                                    <img src="{{item.image}}" alt="Image" class="img-fluid"/>
                                </div>
                                <div class="col-8 text-start mt-3">
                                    <p class="fs-6 fw-bold">{{ item.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<div class="container-fluid mt-5 pt-md-4">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="Image" class="img-fluid"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="brand-primary-text-color fw-bold">Email: <a href="mailto:support@volmint.com"
                                                                          class="text-decoration-underline brand-primary-text-color">support@volmint.com</a>
                    </p>
                    <button type="submit" class="brand-primary-bg border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #5236FF;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What is ITR and Why it is important?</span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The Income Tax Department requires a form by the name of ITR, which must be submitted.
                                It details an individual's earnings and tax obligations for the entire fiscal year,
                                which runs from 1 April to 31 March. Based on the amount of income, the source of the
                                income, and the category to which the taxpayer belongs, the Income Tax Department has
                                created seven ITR forms.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;How To File ITR in India?</span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                You can file your income tax returns online with us or through the
                                income tax department's website. On Acricent, filing an income tax return electronically
                                is simple and secure. Your returns can be electronically filed in about a few minutes
                                without any manual work. You can file ITR on your own or can get help from our experts.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What is Acricent and how it would help me?</span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Acricent is one of the trusted and fastest-growing financial services providers. We are
                                committed to delivering the best possible outcome to our clients. Our services are
                                user-friendly and affordable with a dedicated support team. You can file ITR on your own
                                by using the self-filing option or if you want help, you can choose to fill with a
                                professional option.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What is the Charge for your services?</span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We have two plans; - File ITR on your own and file ITR with a Professional. You can
                                select one of them as per your requirement or you may customize the package as well.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; How Acricent is different from others?</span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our consistent dedication to providing our clients with outstanding value at a very
                                competitive price is what makes us stand out. We develop software that is user-friendly,
                                safe, and effective by fusing the power of cutting-edge technology with a thorough
                                understanding of tax laws. Even for individuals without substantial tax expertise,
                                filing taxes is a snap thanks to our user-friendly interfaces and step-by-step
                                instructions.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10">
                <h1 class="text-white">Ready to elevate your university management to next level?</h1>
            </div>
            <div class="col-md-2">
                <button class="btn bg-white rounded-0 px-5 py-3 fw-bold text-black hover-effect" (click)="goTo('/contact')">Contact Us</button>
            </div>
        </div>
    </div>
</div>
