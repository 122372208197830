<!-- Start Topbar Wrap Area -->
<div
    class="topbar-wrap-area d-none"
    [ngClass]="{'d-block': router?.url === '/home-6' || router?.url === '/home-7'}"
>
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-3 col-md-3">
                <div class="topbar-content">
                    <span>Welcome to Sparsity. Need Help? <a routerLink="/contact">Contact Us</a></span>
                </div>
            </div>
            <div class="col-lg-9 col-md-9">
                <ul class="topbar-action-list">
                    <li><i class="fa-solid fa-location-pin"></i> 2750 Quadra Street Victoria, Canada</li>
                    <li>
                        <i class="fa-solid fa-phone"></i> <a href="tel:44587154756">+44 587 154756</a>
                    </li>
                    <li>
                        <i class="fa-solid fa-envelope"></i> <a href="mailto:sparsity@gmail.com">sparsity@gmail.com</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Topbar Wrap Area -->

<!-- Start Navbar Area -->
<div
    class="{{navClass}}"
    [ngClass]="{'p-relative': router?.url === '/home-5' || router?.url === '/home-8'}"
>
    <div class="volmint-nav ">
        <div class="{{navContainer}}">
            <nav class="navbar navbar-expand-md  navbar-light">
                <a class="navbar-brand d-md-none" routerLink="/">
                    <img src="{{logo}}" class="main-logo" alt="logo">
                    <img src="assets/img/white-volmint-logo.png" class="optional-logo" alt="logo">
                </a>
                <a class="navbar-brand d-none d-md-block" routerLink="/">
                    <img src="{{logo}}" class="main-logo" alt="logo" style="height: 75px;">
                    <img src="assets/img/white-volmint-logo.png" class="optional-logo" alt="logo">
                </a>
                <button (click)="toggleMobileMenu()" class="navbar-toggler animated-toggler bg-transparent text-white border-none" type="button" data-bs-toggle="toggle"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="stick"></span>
                    <span class="stick"></span>
                    <span class="stick"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent" >
                    <div class="d-flex justify-content-between align-items-center border-bottom">
                        <a class=" d-md-none" routerLink="/">
                            <img src="assets/img/white-volmint-logo.png" class="" alt="logo">
                        </a>
                    </div>

                    <ul class="navbar-nav mx-auto">
                        <li class="nav-item color-white">
                            <a [routerLink]="['/about']" class="nav-link text-nowrap fs-5 fw-bold" [ngClass]="listClass"
                               routerLinkActive="active-menu">About Us</a>
                        </li>
                        <li class="nav-item color-white text-nowrap">
                            <a class="fs-5 nav-link fs-5 fw-bold" role="button" [ngClass]="listClass"
                               (click)="toggleMenu()"
                               routerLinkActive="active-menu">Products
                                <i [ngClass]="{'fa-angle-down': !isMenuOpen, 'fa-angle-up': isMenuOpen}"
                                   class="fa fs-6 ms-2"></i>
                            </a>
                        </li>
                        <div class="mega-menu d-flex  justify-content-center p-3 d-none d-md-flex" [ngClass]="listClass"
                             [class.open]="isMenuOpen">
                            <div class="p-5 mega-menu-left-div w-25">
                                <div class="row">
                                    <div class=" d-flex flex-wrap flex-lg-row align-item-center align-items-center">
                                        <div
                                            class="mt-2 mx-auto mx-xl-0 bg-white changing-brand h-sm-auto h-md-100 h-lg-100">
                                            <img [src]="content?.selectedImage || 'assets/img/white-volmint-logo.png'"
                                                 class="w-75 "
                                                 [ngClass]="{'width-30': content?.selectedImage === 'assets/img/project/project-logo/ims.png' }"
                                                 alt="Image">
                                        </div>
                                        <p class="text-white fw-bold fs-2 ms-3">{{ content?.productName || 'Volmint' }}</p>
                                    </div>
                                    <div class=" text-start">
                                        <p class="text-white mt-4 fs-6  font-600">{{ content.selectedText || 'Volmint is an IT solutions, cloud infrastructure, consulting firm.' }}</p>
                                    </div>
                                </div>

                            </div>
                            <div class="px-5 w-75">
                                <div class="row">
                                    <div class="col-6 col-xl-4 my-3" [routerLink]="['/admission-management-system-enroll']"
                                         (mouseover)="changeContent('assets/img/project/project-logo/enroll.png', 'Enroll is the premier solution for managing admissions, offering streamlined processes and enhanced efficiency for handling\n'+
'                            student applications and enrollment.', 'Enroll')"
                                    ><a [routerLink]="['/admission-management-system-enroll']" (click)="closeMenu()"
                                        class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                        <div class="row">
                                            <div class="col-3">
                                                <div
                                                    class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                    <img src="assets/img/project/project-logo/enroll.png"
                                                         class="product-logo"
                                                         alt="Image">
                                                </div>
                                            </div>
                                            <div class="col-9 text-start">
                                                <p class="fs-5 mb-0 fw-bold text-secondary">Enroll
                                                </p>
                                                <p class="text-secondary fs-6">Admission Management System</p>

                                            </div>
                                        </div>
                                    </a>
                                    </div>
                                    <div class="col-6 col-xl-4 my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/ums.png', 'Volmint\'s University Management System (UMS) streamlines university operations, including attendance management, admission tracking, examination processes, and financial integration, with precision and efficiency.', 'UMS')">
                                        <a [routerLink]="['/exam-proctoring-system-argus']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div
                                                        class=" mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/argus.png"
                                                             class="product-logo"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9 text-start">
                                                    <p class=" fs-5 mb-0 fw-bold text-secondary ">Argus
                                                    </p>
                                                    <p
                                                        class="text-secondary fs-6">Exam Proctoring System <span
                                                        class="opacity-0">invisible</span></p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-6 col-xl-4 my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/ums.png', 'Volmint\'s University Management System (UMS) streamlines university operations, including attendance management, admission tracking, examination processes, and financial integration, with precision and efficiency.', 'UMS')">
                                        <a [routerLink]="['/university-management-system']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3 ">
                                                    <div
                                                        class=" mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/ums.png"
                                                             class="product-logo"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9 text-start">
                                                    <p class=" fs-5 mb-0 fw-bold text-secondary ">UMS
                                                    </p>
                                                    <p
                                                        class="text-secondary fs-6">University Management System</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-xl-4  my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/cms-logo.png', 'A College Management System (CMS) is a cloud-based software solution that optimizes administrative and academic functions in educational institutions, catering to faculty, learners, parents, and other staff.', 'CMS')">
                                        <a [routerLink]="['/college-management-system']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div
                                                        class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/cms-logo.png"
                                                             class="product-logo"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9 text-start">
                                                    <p class="fs-5 mb-0 fw-bold text-secondary">CMS
                                                    </p>
                                                    <p class="text-secondary fs-6">College Management System</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-xl-4  my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/cgms-no-bg.png', 'The College Group Management System (CGMS) is a software that enhances collaboration and engagement among students and faculty in campus groups, improving communication and coordination.', 'CGMS')">
                                        <a [routerLink]="['/college-group-management-system']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div
                                                        class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/cgms-no-bg.png"
                                                             class="product-logo"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9 text-start">
                                                    <p class="fs-5 mb-0 fw-bold text-secondary ">CGMS

                                                    </p>
                                                    <p
                                                        class="text-secondary fs-6">College Group Management System</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-xl-4  my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/ims.png', 'Volmint\'s Institute Management System streamlines administrative tasks in educational institutions, automating routine processes like report generation and meeting scheduling.', 'IMS')">
                                        <a [routerLink]="['/institute-management-system']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div
                                                        class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/ims.png"
                                                             class="h-50 w-25"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9 text-start">
                                                    <p class="fs-5 mb-0 fw-bold text-secondary ">IMS
                                                    </p>
                                                    <p
                                                        class="text-seondary fs-6">Institute Management System</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-xl-4  my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/lms-no-bg.png', 'The cloud-based Learning Management System enhances academic management efficiency by facilitating e-learning, improving teacher-student collaboration, administration, and tracking of online courses.', 'LMS')">
                                        <a [routerLink]="['/learning-management-system']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div
                                                        class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/lms-no-bg.png"
                                                             class="h-75"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9 text-start">
                                                    <p class="fs-5 mb-0 fw-bold text-secondary">LMS

                                                    </p>
                                                    <p
                                                        class="text-secondary fs-6">Learning Management System</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-xl-4  my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/eclinic-logo.png', 'E-Clinic Solution simplifies clinic management, saving time on administrative tasks for healthcare chains and practitioners, improving patient satisfaction, and expanding clientele.', 'E-Clinic')">
                                        <a [routerLink]="['/clinic-management-system']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div
                                                        class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/eclinic-logo.png"
                                                             class="product-logo"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9 text-start">
                                                    <p class="fs-5 mb-0 fw-bold text-secondary ">E-clinic

                                                    </p>
                                                    <p
                                                        class="text-secondary fs-6">Digital Clinic Management System</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-xl-4  my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/acricent-logo.png', 'Acricent is a comprehensive tool designed to simplify your income tax filing with efficiency and accuracy.', 'Acricent')">
                                        <a [routerLink]="['/acricent' +
                                         '']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div
                                                        class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/acricent-logo.png"
                                                             class="product-logo"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9 text-start">
                                                    <p class="fs-5 mb-0 fw-bold text-secondary ">Acricent
                                                    </p>
                                                    <p
                                                        class="text-secondary fs-6">IncomeTax Ret. Management System</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-xl-4  my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/v-calling.png', 'Elevate your sales, support, and business teams with V-Calling from Volmint Technologies! Experience seamless global connectivity with crystal-clear HQ voice, AI-powered IVR, and a cutting-edge omnichannel cloud contact center.', 'V-Calling')">
                                        <a [routerLink]="['/lead-management-system-vcalling' +
                                         '']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div
                                                        class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/v-calling.png"
                                                             class="product-logo w-100"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9">
                                                    <p class="fs-5 mb-0 fw-bold text-secondary ">V-Calling
                                                    </p>
                                                    <p
                                                        class="text-secondary fs-6">Volmint Calling System</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-6 col-xl-4  my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/v-calling.png', 'Elevate your sales, support, and business teams with V-Calling from Volmint Technologies! Experience seamless global connectivity with crystal-clear HQ voice, AI-powered IVR, and a cutting-edge omnichannel cloud contact center.', 'V-Calling')">
                                        <a [routerLink]="['/university-and-college-management-system' +
                                         '']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div
                                                        class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/v-calling.png"
                                                             class="product-logo w-100"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9">
                                                    <p class="fs-5 mb-0 fw-bold text-secondary ">UGMS
                                                    </p>
                                                    <p
                                                        class="text-secondary fs-6">UGMS</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-6 col-xl-4  my-3"
                                         (mouseover)="changeContent('assets/img/project/project-logo/v-calling.png', 'Elevate your sales, support, and business teams with V-Calling from Volmint Technologies! Experience seamless global connectivity with crystal-clear HQ voice, AI-powered IVR, and a cutting-edge omnichannel cloud contact center.', 'V-Calling')">
                                        <a [routerLink]="['/vsync' +
                                         '']" (click)="closeMenu()"
                                           class="nav-link d-flex align-items-center light-voilet-bg border-radius-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div
                                                        class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                        <img src="assets/img/project/project-logo/v-calling.png"
                                                             class="product-logo w-100"
                                                             alt="Image">
                                                    </div>
                                                </div>
                                                <div class="col-9">
                                                    <p class="fs-5 mb-0 fw-bold text-secondary ">Vsync
                                                    </p>
                                                    <p
                                                        class="text-secondary fs-6">Vsync</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <hr>
                                <div class="d-flex justify-content-between">
                                    <div class="w-25 d-flex justify-content-between">
                                        <a href="https://www.linkedin.com/company/volmint-technologies/"
                                           target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-linkedin"></i></span></a>
                                        <a href=" https://twitter.com/TechVolmint" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-twitter"></i></span></a>
                                        <a href="https://www.instagram.com/volminttechnologies/" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-instagram"></i></span></a>
                                        <a href="https://www.facebook.com/VolmintTechnologies" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-facebook-f"></i></span></a>
                                        <a href="https://www.youtube.com/channel/UChR9FpeL0Jm1a4ks-rOowPA"
                                           target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-youtube"></i></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Mega Menu for Small Screens -->
                        <div class="mega-menu-small d-flex flex-column d-md-none"
                             [ngClass]="{'d-block': isMenuOpen, 'd-none': !isMenuOpen}">
                            <ul class="list-unstyled d-flex flex-wrap justify-content-center p-0">
                                <li class=" p-0 ">
                                    <a href="https://enroll.volmint.com/" target="_blank"
                                       class="card text-center p-2 w-100">
                                        <div class="">
                                            <img src="assets/img/project/project-logo/enroll.png" class=""
                                                 alt="Image">
                                        </div>
                                        <p class="mb-0">Enroll
                                        </p>
                                    </a>
                                </li>
                                <li class=" p-0">
                                    <a [routerLink]="['/argus']" (click)="closeMenu()"
                                       class="card p-2 text-center w-100">
                                        <div class="">
                                            <img src="assets/img/project/project-logo/argus.png" class=""
                                                 alt="Image">
                                        </div>
                                        <p class="mb-0">Argus
                                        </p>
                                    </a>
                                </li>
                                <li class="p-0 ">
                                    <a [routerLink]="['/ums']" (click)="closeMenu()"
                                       class="p-2 card text-center w-100">
                                        <div class="">
                                            <img src="assets/img/project/project-logo/ums.png" class=""
                                                 alt="Image">
                                        </div>
                                        <p class="mb-0">UMS
                                        </p>
                                    </a>
                                </li>
                                <li class="p-0">
                                    <a [routerLink]="['/cms']" (click)="closeMenu()"
                                       class="p-2 card text-center w-100">
                                        <div>
                                            <img src="assets/img/project/project-logo/cms-logo.png"
                                                 class=""
                                                 alt="Image">
                                        </div>
                                        <p class="mb-0">CMS
                                        </p>
                                    </a>
                                </li>
                                <li class="p-0 ">
                                    <a [routerLink]="['/cgms']" (click)="closeMenu()"
                                       class="p-2 card text-center w-100">
                                        <div>
                                            <img src="assets/img/project/project-logo/cgms-no-bg.png"
                                                 class=""
                                                 alt="Image">
                                        </div>
                                        <p class="mb-5">CGMS
                                        </p>
                                    </a>
                                </li>
                                <li class="p-0">
                                    <a [routerLink]="['/ims']" (click)="closeMenu()"
                                       class="p-2 card text-center w-100">
                                        <div>
                                            <img src="assets/img/project/project-logo/ims.png"  height="40"
                                                 alt="Image">
                                        </div>
                                        <p class="\ mb-0">IMS
                                        </p>
                                    </a>
                                </li>
                                <li class="p-0">
                                    <a [routerLink]="['/lms']" (click)="closeMenu()"
                                       class="p-2 card text-center w-100">
                                        <div>
                                            <img src="assets/img/project/project-logo/lms-no-bg.png"
                                                 class=""
                                                 alt="Image">
                                        </div>
                                        <p class=" mb-0">LMS
                                        </p>
                                    </a>
                                </li>
                                <li class="p-0">
                                    <a [routerLink]="['/eclinic']" (click)="closeMenu()"
                                       class="p-2 card text-center w-100">
                                        <div>
                                            <img src="assets/img/project/project-logo/eclinic-logo.png"
                                                 class="" alt="Image">
                                        </div>
                                        <p class=" mb-0">E-clinic
                                        </p>
                                    </a>
                                </li>
                                <li class="p-0">
                                    <a [routerLink]="['/acricent']" (click)="closeMenu()"
                                       class="p-2 card text-center w-100">
                                        <div>
                                            <img src="assets/img/project/project-logo/acricent-logo.png"
                                                 height="22"
                                                 alt="Image">
                                        </div>
                                        <p class="mb-0">Acricent
                                        </p>
                                    </a>
                                </li>
                                <li class="p-0">
                                    <a [routerLink]="['/vcalling']" (click)="closeMenu()"
                                       class="p-2 card text-center w-100">
                                        <div>
                                            <img src="assets/img/project/project-logo/v-calling-black.png"
                                                 height="32"
                                                 alt="Image">
                                        </div>
                                        <p class="fs-5\ mb-0">V-Calling
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <li class="nav-item color-white">
                            <a [routerLink]="['/careers']" class="nav-link fs-5 fw-bold" [ngClass]="listClass"
                               routerLinkActive="active-menu">Careers</a>
                        </li>

                        <li class="nav-item color-white text-nowrap">
                            <a class="nav-link fs-5 fw-bold cursor-pointer" role="button" [ngClass]="listClass"
                               routerLinkActive="active-menu" (click)="toggleServiceMenu()">Services
                                <i [ngClass]="{'fa-angle-down': !isServiceMenuOpen, 'fa-angle-up': isServiceMenuOpen}"
                                   class="fa fs-6 ms-2"></i>
                            </a>
                        </li>
                        <div class="mega-menu d-flex justify-content-between p-3 d-none d-md-flex" [ngClass]="listClass"
                             [class.open]="isServiceMenuOpen">
                            <div class=" w-25 pt-4 serviceMenu">
                                <ul class="mx-4 ">
                                    <li class="ps-2 my-3 w-100 text-start cursor-pointer"
                                        (mouseover)="changeServiceContent('DIGITAL_MARKETING')">
        <span style="font-size: 24px;" class="text-white  "
              [ngClass]="{'acitvemenubar': selectedServiceText === 'DIGITAL_MARKETING'}">
            <i class="fa-solid me-2 fa-icons"></i>
            Digital Marketing
        </span>
                                    </li>
                                    <li class="w-100 px-0 cursor-pointer text-start my-3"
                                        (mouseover)="changeServiceContent('MOBILE_APPLICATION')">
        <span style="font-size: 24px;" class="text-white"
              [ngClass]="{'acitvemenubar': selectedServiceText === 'MOBILE_APPLICATION'}">
            <i class="fa-solid me-2 fa-code"></i>
            Mobile Applications
        </span>
                                    </li>
                                    <li class="w-100 px-0 my-3 text-start cursor-pointer"
                                        (mouseover)="changeServiceContent('WEBSITE_DEVELOPMENT')">
        <span style="font-size: 24px;" class="text-white"
              [ngClass]="{'acitvemenubar': selectedServiceText === 'WEBSITE_DEVELOPMENT'}">
            <i class="fa-solid me-2 fa-laptop-code"></i>
            Web Development
        </span>
                                    </li>
                                    <li class="w-100 px-0 text-start cursor-pointer my-3"
                                        (mouseover)="changeServiceContent('SERVER_MANAGEMENT')">
        <span style="font-size: 24px;" class="text-white "
              [ngClass]="{'acitvemenubar': selectedServiceText === 'SERVER_MANAGEMENT'}">
            <i class="fa-solid me-2 fa-server"></i>
            Server Management
        </span>
                                    </li>
                                </ul>

<!--                                <table class="mx-5  table table-responsive text-center" style="width: 350px">-->
<!--                                    <tbody class="">-->
<!--                                    <tr class="py-5 border-radius-10 cursor-pointer"-->
<!--                                        (mouseover)="changeServiceContent('DIGITAL_MARKETING')"-->
<!--                                    >-->
<!--                                        <td class="border-none text-start border-radius-10"-->
<!--                                        >-->
<!--                                            <span style="font-size: 24px;" class="text-white px-5 "-->
<!--                                                  [ngClass]="{'acitvemenubar': selectedServiceText==='DIGITAL_MARKETING'}"> Digital Marketing-->
<!--                                            </span>-->
<!--                                        </td>-->
<!--                                    </tr>-->
<!--                                    <tr class="  cursor-pointer"-->
<!--                                        (mouseover)="changeServiceContent('MOBILE_APPLICATION')"-->
<!--                                    >-->
<!--                                        <td class="border-none text-start border-radius-10 w-50">-->
<!--                                            <span style="font-size: 24px;"-->
<!--                                                  [ngClass]="{'acitvemenubar': selectedServiceText==='MOBILE_APPLICATION'}"-->
<!--                                                  class="text-white px-5"> Mobile Applications-->
<!--                                            </span>-->
<!--                                        </td>-->
<!--                                    </tr>-->
<!--                                    <tr class=" border-radius-10 cursor-pointer"-->
<!--                                        (mouseover)="changeServiceContent('WEBSITE_DEVELOPMENT')"-->
<!--                                    >-->
<!--                                        <td class="border-none text-start border-radius-10 px-5"-->
<!--                                        >-->
<!--                                            <span style="font-size: 24px;"-->
<!--                                                  [ngClass]="{'acitvemenubar': selectedServiceText==='WEBSITE_DEVELOPMENT'}"-->
<!--                                                  class="text-white"> Website Development</span>-->
<!--                                        </td>-->
<!--                                    </tr>-->
<!--                                    <tr class=" border-radius-10 cursor-pointer"-->
<!--                                        (mouseover)="changeServiceContent('SERVER_MANAGEMENT')"-->
<!--                                    >-->
<!--                                        <td class="border-none text-start border-radius-10 px-5 "-->
<!--                                        >-->
<!--                                            <span style="font-size: 24px;" class="text-white"-->
<!--                                                  [ngClass]="{'acitvemenubar': selectedServiceText==='SERVER_MANAGEMENT'}"> Server Management-->
<!--                                            </span>-->
<!--                                        </td>-->
<!--                                    </tr>-->
<!--                                    </tbody>-->
<!--                                </table>-->
                                <!--ul class="d-flex justify-content-between flex-wrap">
                                    <li class="w-25 my-3 ">
                                        <a (click)="closeMenu()"
                                           (mouseover)="changeServiceContent('assets/img/services/image_1.png', 'At Volmint Technologies, we offer comprehensive digital marketing services designed to help businesses grow and thrive in today\'s competitive online landscape. Our expert team specializes in search engine optimization (SEO), social media marketing, content creation, pay-per-click (PPC) campaigns, and email marketing to increase your brand’s visibility and drive targeted traffic to your website. By leveraging data-driven strategies and cutting-edge tools, we tailor our solutions to meet your unique business goals, ensuring maximum return on investment. Let us help you connect with your audience, build your brand, and achieve long-term success.')"
                                           class="nav-link d-flex cursor-pointer">
                                            <div class="bg-f2f6f9 -bg border">
                                                <img src="assets/img/services/image_1.png"
                                                     class="product-logo cover-background w-100"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mb-0"><span
                                                [routerLink]="['/search-engine-optimization-service']">Digital Marketing</span><br/>
                                                <span [routerLink]="['/search-engine-optimization-service']"
                                                      (click)="closeMenu()"
                                                      class="text-secondary fs-6 services">Search Engine Optimization (SEO)</span><br/>
                                                <span [routerLink]="['/social-media-marketing-service']"
                                                      (click)="closeMenu()"
                                                      class="text-secondary fs-6 services">Social Media Marketing (SMM)</span><br/>
                                                <span [routerLink]="['/content-marketing']" (click)="closeMenu()"
                                                      class="text-secondary fs-6 services">Content Marketing</span><br/>
                                                <span [routerLink]="['/advertising-service']" (click)="closeMenu()"
                                                      class="text-secondary fs-6 services">Advertising</span>
                                            </p>
                                        </a>
                                    </li>
                                    <li class="w-25 my-3 "
                                        (mouseover)="changeServiceContent('assets/img/services/image_2.png', 'At Volmint Technologies, we provide innovative mobile application development services that turn your ideas into dynamic, user-friendly apps. Our skilled developers specialize in creating high-performance mobile applications for both Android and iOS platforms, ensuring seamless functionality and an engaging user experience. Whether you need a business app, e-commerce platform, or custom solution, we use the latest technologies to design and build apps tailored to your specific requirements. From concept to launch, we ensure that your mobile app not only meets but exceeds user expectations, helping your business stay ahead in the fast-evolving mobile landscape.')">
                                        <a [routerLink]="['/mobile-application-development']" (click)="closeMenu()"
                                           class="nav-link d-flex">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/services/image_2.png"
                                                     class="product-logo w-100"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mb-0">Mobile Applications<br/>
                                                <span class="text-secondary fs-6 services"
                                                      routerLink="/mobile-application-development">Android Application Development</span><br/>
                                                <span
                                                    class="text-secondary fs-6 services"
                                                    routerLink="/mobile-application-development">IOS Application Development</span>
                                            </p>
                                        </a>
                                    </li>
                                    <li class="w-25 my-3 "
                                        (mouseover)="changeServiceContent('assets/img/services/image_3.png', 'At Volmint Technologies, we offer expert website development services to help businesses establish a strong online presence. Our team specializes in creating custom websites that are not only visually appealing but also optimized for performance, responsiveness, and user experience. Whether you need a corporate website, e-commerce platform, or a content-driven site, we build solutions that align with your brand identity and business objectives. By using the latest web technologies and best practices in SEO and security, we ensure that your website stands out and drives meaningful results. Let us help you build a website that enhances your digital footprint and connects with your audience effectively.')">
                                        <a [routerLink]="['/website-development']" (click)="closeMenu()"
                                           class="nav-link d-flex">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/services/image_3.png" class="product-logo w-100"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mb-0">Website Development<br/>
                                                <span class="text-secondary fs-6 services">HTML CSS JAVASCRIPT</span>
                                                <br/>
                                                <span class="text-secondary fs-6 services">TAILWIND CSS</span> <br/>
                                                <span class="text-secondary fs-6 services">JQUERY</span> <br/>
                                                <span class="text-secondary fs-6 services">ANGULAR</span> <br/>
                                                <span class="text-secondary fs-6 services">MATERIAL UI</span> <br/>
                                            </p>
                                        </a>
                                    </li>
                                    <li class="w-25 my-3 "
                                        (mouseover)="changeServiceContent('assets/img/services/image_4.png', 'At Volmint Technologies, we provide reliable and efficient server management services to ensure your IT infrastructure runs smoothly and securely. Our expert team handles everything from server setup and configuration to ongoing monitoring, maintenance, and troubleshooting, minimizing downtime and maximizing performance. We specialize in managing cloud servers, dedicated servers, and hybrid environments, ensuring optimal security, scalability, and efficiency. With 24/7 support and proactive monitoring, we safeguard your data and ensure your servers are always running at peak efficiency, allowing you to focus on your core business while we handle your server needs.')">
                                        <a [routerLink]="['/server-management']" (click)="closeMenu()"
                                           class="nav-link d-flex">
                                            <div class="bg-f2f6f9 product-logo-bg border">
                                                <img src="assets/img/services/image_4.png"
                                                     class="product-logo w-100"
                                                     alt="Image">
                                            </div>
                                            <p class="fs-5 mb-0">Server Management<br/>
                                                <span class="text-secondary fs-6 services">Comprehensive Managed Cloud Services</span><br/>
                                                <span class="text-secondary fs-6 services">DevOps and DevSecOps Integration</span><br/>
                                                <span
                                                    class="text-secondary fs-6 services">Database and Monitoring Solutions</span><br/>
                                                <span
                                                    class="text-secondary fs-6 services">IT Consulting and Compliance Services</span><br/>
                                            </p>
                                        </a>
                                    </li>
                                </ul>-->
                                <!--div class="d-flex justify-content-between">
                                    <div class="w-25 d-flex justify-content-between">
                                        <a href="https://www.linkedin.com/company/volmint-technologies/"
                                           target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-linkedin"></i></span></a>
                                        <a href=" https://twitter.com/TechVolmint" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-twitter"></i></span></a>
                                        <a href="https://www.instagram.com/volminttechnologies/" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-instagram"></i></span></a>
                                        <a href="https://www.facebook.com/VolmintTechnologies" target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-facebook-f"></i></span></a>
                                        <a href="https://www.youtube.com/channel/UChR9FpeL0Jm1a4ks-rOowPA"
                                           target="_blank"><span
                                            class="rounded-circle bg-light-pink p-2 d-flex align-items-center justify-content-center "
                                            style="width: 50px; height: 50px"><i
                                            class="fa-brands fa-youtube"></i></span></a>
                                    </div>
                                </div>-->
                            </div>
                            <div class="p-5 w-75 over-flow-hidden">
                                <div class="row" *ngIf="selectedServiceText == 'DIGITAL_MARKETING'">
                                    <div (click)="closeMenu()"
                                         class="col-6 col-xxl-4  mb-3 cursor-pointer animated-div1">
                                        <div class="card light-voilet-bg "
                                             routerLink="search-engine-optimization-service">
                                            <div class="card-body">
                                                <div class="row align-items-center px-0">
                                                    <div class="col-2">
                                                        <div
                                                            class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/social-media-marketing/seo.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10  text-start ">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary ">
                                                            Search Engine Optimization</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()"
                                         class="col-6 col-xxl-4  mb-3 cursor-pointer animated-div2">
                                        <div class="card light-voilet-bg" routerLink="social-media-marketing-service">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/social-media-marketing/smm.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start px-2">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Social Media Marketing</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()"
                                         class="col-6 col-xxl-4  mb-3 cursor-pointer animated-div3">
                                        <div class="card light-voilet-bg" routerLink="content-marketing">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/social-media-marketing/cm.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start px-2">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Content Marketing</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()"
                                         class="col-6 col-xxl-4  mb-3 cursor-pointer animated-div1">
                                        <div class="card light-voilet-bg" routerLink="advertising-service">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/social-media-marketing/ad.png"
                                                                class=" product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start px-2">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Advertising</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="selectedServiceText == 'MOBILE_APPLICATION'">
                                    <div (click)="closeMenu()"
                                         class="col-6 col-xxl-4 mb-3 cursor-pointer animated-div1">
                                        <div class="card light-voilet-bg" routerLink="mobile-application-development">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/mobile-development/android.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Android Application Development</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()"
                                         class="col-6 col-xxl-4 mb-3 cursor-pointer animated-div2">
                                        <div class="card light-voilet-bg" routerLink="mobile-application-development">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img src="assets/img/landing-new/mobile-development/ios.png"
                                                                 class="product-logo" style="width: 120px;"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            IOS Application Development</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="selectedServiceText == 'WEBSITE_DEVELOPMENT'">
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div1">
                                        <div class="card light-voilet-bg" routerLink="website-development">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class="bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/website-development/interface_15236703.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Front-end Development</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div2">
                                        <div class="card light-voilet-bg" routerLink="website-development">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class=" bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/website-development/upgrade_11766101.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Back-end Development</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row gy-3" *ngIf="selectedServiceText == 'SERVER_MANAGEMENT'">
                                    <p class="text-start  fs-5 fw-bold text-secondary">Cloud & DevOps Services :</p>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div1">
                                        <div class="card light-voilet-bg" routerLink="server-management">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class=" bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/server-management/cloud.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Managed Cloud Services</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div2">
                                        <div class="card light-voilet-bg" routerLink="server-management">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class=" bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/server-management/devops.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Devops</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div3">
                                        <div class="card light-voilet-bg" routerLink="server-management">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/server-management/container.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Containers</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="text-start  fs-5 fw-bold text-secondary">Security & Compliance :</p>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div1">
                                        <div class="card light-voilet-bg" routerLink="database-devops-serverless">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class=" bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/server-management/devsecops.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            DevSecOps</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div2">
                                        <div class="card light-voilet-bg" routerLink="database-devops-serverless">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class=" bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/server-management/counselting.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Compliance Audit and Consulting</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div3">
                                        <div class="card light-voilet-bg" routerLink="database-devops-serverless">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img src="assets/img/landing-new/server-management/it.png"
                                                                 class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            IT Consulting Services</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="text-start  fs-5 fw-bold text-secondary">Serverless & Integration :</p>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div1">
                                        <div class="card light-voilet-bg" routerLink="server-migration-monitoring">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class=" bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/server-management/monitor.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Monitoring</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div2">
                                        <div class="card light-voilet-bg" routerLink="server-migration-monitoring">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class=" bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/server-management/serverless.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Serverless Computing</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div3">
                                        <div class="card light-voilet-bg" routerLink="server-migration-monitoring">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class=" bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/server-management/migrate.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Migration and integration</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="text-start  fs-5 fw-bold text-secondary">Public Cloud & Delivery :</p>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div1">
                                        <div class="card light-voilet-bg" routerLink="cloud-db-cdn">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img src="assets/img/landing-new/server-management/db.png"
                                                                 class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Database Service</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div2">
                                        <div class="card light-voilet-bg" routerLink="cloud-db-cdn">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class=" bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img
                                                                src="assets/img/landing-new/server-management/servicecloud.png"
                                                                class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Managed Public Cloud Services</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div (click)="closeMenu()" class="col-6 col-xxl-4 cursor-pointer animated-div3">
                                        <div class="card light-voilet-bg" routerLink="cloud-db-cdn">
                                            <div class="card-body">
                                                <div class="row align-items-center">
                                                    <div class="col-2">
                                                        <div
                                                            class="mt-2 bg-white product-logo-bg border h-sm-auto h-md-100 h-lg-100">
                                                            <img src="assets/img/landing-new/server-management/cdn.png"
                                                                 class="product-logo"/>
                                                        </div>
                                                    </div>
                                                    <div class="col-10 text-start">
                                                        <p class="ms-sm-2 fs-6 ms-md-3 ms-lg-4 ms-xl-2 ms-xxl-0  mb-0 fw-bold text-secondary">
                                                            Content Delivery Network</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Mega Menu for Small Screens -->
                        <div class="mega-menu-small d-flex flex-column p-3 d-md-none"
                             [ngClass]="{'d-block': isServiceMenuOpen, 'd-none': !isServiceMenuOpen}"
                        >
                            <ul class="list-unstyled">
                                <li class="d-flex align-items-center  py-0">
                                    <a target="_blank"
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 text-center">
                                            <img src="assets/img/services/image_1.png" class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <div class="dropdown" (click)="toggleDropdown()">
                                            <button class="dropdown-button bg-transparent fw-bold text-secondary ">Digital Marketing</button>
                                            <div class="dropdown-content" [class.show]="isDropdownOpen">
                                                <a routerLink="search-engine-optimization-service">Search Engine Optimization (SEO)</a>
                                                <a routerLink="social-media-marketing-service">Social Media Marketing (SMM)</a>
                                                <a routerLink="content-marketing">Content Marketing</a>
                                                <a routerLink="advertising-service">Advertising</a>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center  py-0">
                                    <a
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 text-center">
                                            <img src="assets/img/services/image_2.png" class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <div class="dropdown" (click)="toggleMobileApps()">
                                            <button class="dropdown-button bg-transparent fw-bold text-secondary">Mobile Applications</button>
                                            <div class="dropdown-content" [class.show]="mobileApp">
                                                <a routerLink="mobile-application-development">Android Application Development</a>
                                                <a routerLink="ios-application-development">IOS Application Development</a>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center py-0">
                                    <a
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 text-center">
                                            <img src="assets/img/services/image_3.png" class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <div class="dropdown" (click)="webDevelopment()">
                                            <button class="dropdown-button bg-transparent fw-bold text-secondary">Website Development</button>
                                            <div class="dropdown-content" [class.show]="webDevs">
                                                <a  routerLink="website-development">Front-End Development</a>
                                                <a  routerLink="website-development">Back-End Development</a>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="d-flex align-items-center py-0 ">
                                    <a
                                       class="d-flex align-items-center w-100">
                                        <div class="bg-f2f6f9 text-center">
                                            <img src="assets/img/services/image_4.png"
                                                 class="product-logo"
                                                 alt="Image">
                                        </div>
                                        <div class="dropdown" (click)="toggleServer()">
                                            <button class="dropdown-button bg-transparent text-secondary fw-bold">Server Management</button>
                                            <div class="dropdown-content " [class.show]="serverMenu">
                                                <a  routerLink="server-management">Managed Cloud Services</a>
                                                <a  routerLink="server-management">Devops</a>
                                                <a  routerLink="server-management">Containers</a>
                                                <a  routerLink="database-devops-serverless">DevSecOps</a>
                                                <a  routerLink="database-devops-serverless">Compliance Audit and Consulting</a>
                                                <a  routerLink="database-devops-serverless">IT Consulting Services</a>
                                                <a  routerLink="server-migration-monitoring">Monitoring</a>
                                                <a  routerLink="server-migration-monitoring">Serverless Computing</a>
                                                <a  routerLink="server-migration-monitoring">Migration and integration</a>
                                                <a  routerLink="cloud-db-cdn">Datebase Service</a>
                                                <a  routerLink="cloud-db-cdn">Management Public Could Services</a>
                                                <a  routerLink="cloud-db-cdn">Content Delivery Network</a>

                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ul>
                    <div class="px-3 d-flex gap-3">
                        <button class="px-lg-3 text-nowrap py-lg-2 landing-button border-0 fs-6  bg-grey fw-bold" *ngIf="router.url !== '/refer-and-earn'"
                                routerLink="/refer-and-earn" routerLinkActive="active">
                            Partner & Profit
                            <i class="fa fa-coins"></i>
                        </button>
                        <button class=" px-2 px-lg-3 text-nowrap py-lg-2 landing-button border-0 fs-6 nav-link bg-grey fw-bold"
                                routerLink="/contact" routerLinkActive="active">Contact Us <i class="fa fa-arrow-right"
                                                                                              ></i>
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
