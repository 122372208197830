import {Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ContactUsService} from '../../../shared/services/contact-us.service';
import {AlertService} from '../../../shared/services/alert.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-social-media-marketing',
    templateUrl: './social-media-marketing.component.html',
    styleUrls: ['./social-media-marketing.component.scss']
})
export class SocialMediaMarketingComponent implements OnInit, AfterViewInit {

    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    strategySteps = [
        { icon: 'fa-regular fa-copyright', title: 'Understanding Your Brand', description: 'We examine your brand’s vision, audience, and goals to craft a tailored roadmap.' },
        { icon: 'fa-brands fa-intercom', title: 'Planning and Strategy', description: 'A customized strategy with clear objectives, KPIs, and timelines for success.' },
        { icon: 'fa-regular fa-pen-to-square', title: 'Content Creation', description: 'Engaging content that resonates with your audience and strengthens brand identity.' },
        { icon: 'fa-solid fa-calendar-check', title: 'Execution', description: 'Timely scheduling and publishing to ensure maximum reach and visibility.' },
        { icon: 'fa-brands fa-wpforms', title: 'Engagement and Monitoring', description: 'Active management, responding to queries, and fostering interactions.' },
        { icon: 'fa-solid fa-chart-column', title: 'Review and Optimize', description: 'Data-driven analysis to enhance campaign performance and maintain competitiveness.' }
    ];
    powerfullToUse = [

        {text: 'Enhanced Brand Awareness'},
        {text: 'Targeted Advertising'},
        {text: 'Improved Customer Engagement'},
        {text: 'Valuable Insights and Analytics'},
        {text: 'Comprehensive Strategy Development'},
        {text: 'Continuous Optimization'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/argus/monitoring.png', text: 'Content Creation and Curation'},
        {icon: 'assets/img/landing-new/argus/analytics.png', text: 'Audience Targeting'},
        {icon: 'assets/img/landing-new/argus/custom.png', text: 'Ad Campaign Management'},
        {icon: 'assets/img/landing-new/argus/user.png', text: 'Social Listening'},
        {icon: 'assets/img/landing-new/argus/multi.png', text: 'Performance Analytics'},
        {icon: 'assets/img/landing-new/argus/hybrid.png', text: 'Community Management'},
        {icon: 'assets/img/landing-new/argus/automated.png', text: 'Influencer Partnerships'},
        {icon: 'assets/img/landing-new/argus/scalability.png', text: 'Cross-Platform Integration'},
        {icon: 'assets/img/services/ongoing_support.jpeg', text: 'Reputation Management'}

    ];
    whyChoose = [
        {
            header: 'Brand Awareness ',
            text: 'Social media optimization exposes your brand to a vast audience, increasing trust while keeping your name top-of-mind for potential customers.'
        },
        {
            header: 'Boosts Audience Engagement',
            text: 'Regular posts, stories, and updates foster direct interaction with your audience, creating a loyal community that resonates with your brand values.'
        },
        {
            header: 'Generates Leads & Sales',
            text: 'Effective social media strategies not only attract followers but also convert them into customers through targeted campaigns and calls to action.'
        },
        {
            header: 'Cost-Effective Marketing',
            text: 'Compared to traditional marketing methods, social media offers a cost-efficient way to reach your audience with measurable results and growth.'
        },
        {
            header: 'Stays Ahead of Competition',
            text: 'With consistent and strategic efforts, your business can outperform competitors and dominate its niche on social media helping to stay ahead of competitors.'
        },
        {
            header: 'Real-Time Market Insights',
            text: 'Analyze trends, understand customer preferences, and refine your strategies using data-driven insights directly from your loyal audience.'
        },
    ];
    offerings = [
        {
            text: 'Strategic Planning'
        },
        {
            text: 'Content Creation'
        },
        {
            text: 'Targeted Advertising'
        },
        {
            text: 'Community Management'
        },
        {
            text: 'Performance Tracking'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private title: Title,
        private meta: Meta,
        private renderer: Renderer2
    ) {
    }

    ngOnInit(): void {
        this.refresh();
        this.updateMetaTags();

    }

    ngAfterViewInit() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Add the "visible" class when the element enters the viewport
                    entry.target.classList.add('visible');
                } else {
                    // Remove the "visible" class when the element leaves the viewport
                    entry.target.classList.remove('visible');
                }
            });
        });
        // Select all elements with the class "animate-on-scroll"
        const elements = document.querySelectorAll('.animate-on-scroll, .animate-on-scroll-right');

        // Observe each element
        elements.forEach(element => {
            observer.observe(element);
        });
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });

        this.schema();
    }

    schema() {
        const corporationSchema = {
            "@context": "https://schema.org",
            "@type": "SocialMediaMarketingServicePage",
            "name": "Volmint Technology - Social-Media-Marketing-Service",
            "url": "https://www.volmint.com/social-media-marketing-service",
            "logo": "https://www.volmint.com/assets/img/white-volmint-logo.png",

        };

        // FAQ Schema
        const faqSchema = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": "How is content created for social media marketing?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "     Social media marketing content is crafted based on the target audience, business goals, and platform requirements. A social media management company or consultant often conducts research, designs engaging visuals, and writes compelling captions to resonate with your audience while optimizing posts for visibility and engagement."
                    }
                },
                {
                    "@type": "Question",
                    "name": " How much does social media management cost?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "   The cost for social media management services may vary as per the business recruitment and goals. Usually, the cost for basic to advanced SMM may range between INR 10,000 - 70,000 PM."
                    }
                },
                {
                    "@type": "Question",
                    "name": "How long does it take to see results from social media marketing?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "     Results can vary depending on your goals and strategy. Organic growth often takes several months, while paid ads can deliver quicker results. A social media management consultant can guide you on the timeline based on your specific business needs."
                    }
                },
                {
                    "@type": "Question",
                    "name": "Is it better to focus on organic growth or paid social media ads?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "  Both approaches have value. Organic growth builds trust and long-term engagement, while paid ads drive immediate visibility and conversions. A balanced strategy crafted by a social media management company can help you achieve the best results."
                    }
                },
                {
                    "@type": "Question",
                    "name": "How do I choose the right social media strategy for my business?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "   The right strategy depends on your target audience, industry, and goals. Consulting with a social media management agency or expert can help you analyze your needs, identify key platforms, and create a customized plan for success."
                    }
                },


            ]
        };

        // Add Corporation Schema
        const corpScript: HTMLScriptElement = this.renderer.createElement('script');
        corpScript.type = 'application/ld+json';
        corpScript.text = JSON.stringify(corporationSchema);
        this.renderer.appendChild(document.head, corpScript);

        // Add FAQ Schema
        const faqScript: HTMLScriptElement = this.renderer.createElement('script');
        faqScript.type = 'application/ld+json';
        faqScript.text = JSON.stringify(faqSchema);
        this.renderer.appendChild(document.head, faqScript);
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                name: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                email: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                contact: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                subject: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                message: (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    name: '',
                    email: '',
                    contact: '',
                    subject: '',
                    message: ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error.error && error.error.errors && error.error.hasOwnProperty('errors')) {
                    if (error.error.errors.email && error.error.errors.email.length > 0) {
                        this.alertService.showErrors(error.error.errors.email[0]);
                        return;
                    }
                    if (error.error.errors.name && error.error.errors.name.length > 0) {
                        this.alertService.showErrors(error.error.errors.name[0]);
                        return;
                    }
                    if (error.error.errors.contact && error.error.errors.contact.length > 0) {
                        this.alertService.showErrors(error.error.errors.contact[0]);
                        return;
                    }
                    if (error.error.errors.subject && error.error.errors.subject.length > 0) {
                        this.alertService.showErrors(error.error.errors.subject[0]);
                        return;
                    }
                    if (error.error.errors.message && error.error.errors.message.length > 0) {
                        this.alertService.showErrors(error.error.errors.message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
    updateMetaTags() {
        this.title.setTitle('Increase sales with Volmint’s social media management services');
        this.meta.updateTag({name: 'description', content: ' Volmint Technologies\' Social Media Management services help to enhance brand awareness, boost audience engagement to generate potential leads and sales. '});
        this.meta.updateTag({name: 'keyword', content: 'social media management company, social media management agency, social media optimization, social media management consultant'});
    }

}
