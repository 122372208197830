<div class="page-title-area item-bg1">
    <app-background-animation></app-background-animation>
    <div class="container">
        <div class="page-title-content">
            <h1 class="fs-5 color-white text-center glowing-heading">Case Study</h1>
            <h1 class="fs-1 color-white text-center fw-bold glowing-sub-heading">Delivering Excellence Across
                Industries</h1>
            <div class="mt-5 text-center">
                <button
                    class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold glowing-button glowing-sub-heading"
                    (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->


<section class="banner ">
    <div class="container-fluid bg  pt-6 mt-5">
        <div class="container mt-6 ">
            <h1 class="text-dark font-60 fw-bold  mx-3">Case Studies</h1>
            <p class="mx-3 f-20 text-secondary">Volmint Technologies is one of the most diversified IT giants with a
                mission to empower businesses through the ease of technology. It offers several software or cloud-based
                systems that help to automate universities' internal operations or other organizational operations.
                Scroll down and learn about how Volmint’s products and services create a difference that transforms the
                educational landscape. </p>
            <div class="row">
                <div class="container mt-6 pt-5">
                    <div class="row">
                        <!-- Image and Content Section -->
                        <div class="col-12 d-flex flex-column flex-lg-row align-items-center px-0">
                            <!-- Image on Left (col-12 on mobile, col-6 on larger screens) -->
                            <div class="col-12 col-lg-6 mb-4 mb-lg-0">
                                <img src="assets/img/case-study/subharti.png" class="img-fluid" height="350"
                                     alt="Subharti University">
                            </div>

                            <!-- Content on Right (col-12 on mobile, col-6 on larger screens) -->
                            <div class="col-12 col-lg-6 py-3 px-3 d-flex align-items-center subharti-bg">
                                <div class="py-5 text-center">
                                    <span class="fw-bold case-study">Case Study</span>
                                    <h2 class="fw-bolder font-56 mt-4">Subharti University</h2>
                                    <p class="my-4 fs-6 f-20 justify-text">
                                        Subharti University, a prestigious institution known for its academic excellence
                                        not only in the traditional learning system but for online and distance learning
                                        courses.
                                    </p>
                                    <button class="custom-btn btn-12" [routerLink]="'subharti-university'">
                                        <span>Click!</span><span>Read More</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container mt-6 pt-5">
                    <div class="row">
                        <!-- Image and Content Section -->
                        <div class="col-12 d-flex flex-column flex-lg-row align-items-center px-0">
                            <!-- Image on Left (col-12 on mobile, col-6 on larger screens) -->
                            <div
                                class="col-12 col-lg-6 mb-4 mb-lg-0 d-flex align-items-center justify-content-center equal-height">
                                <img src="assets/img/case-study/ms-group.jpg" class="img-fluid" alt="MS GROUP"
                                     style="max-height: 350px; object-fit: cover;">
                            </div>

                            <!-- Content on Right (col-12 on mobile, col-6 on larger screens) -->
                            <div
                                class="col-12 col-lg-6 py-3 px-3 d-flex align-items-center justify-content-center equal-height subharti-bg">
                                <div class="py-3 text-center">
                                    <span class="fw-bold case-study">Case Study</span>
                                    <h2 class="fw-bolder font-56 mt-4">MS Institute of Education</h2>
                                    <p class="my-4 fs-6 f-20 justify-text">
                                        MS Institute of Education is on a mission to remove distance as a barrier in
                                        terms of education. Having experience of more than 20 years in the field of
                                        distance and online education in India.
                                    </p>
                                    <button class="custom-btn btn-12" [routerLink]="'ms-group-of-institute'"><span>Click!</span><span>Read More</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="container mt-6 pt-5">
                    <div class="row">
                        <!-- Image and Content Section -->
                        <div class="col-12 d-flex flex-column flex-lg-row align-items-center px-0">
                            <!-- Image on Left (col-12 on mobile, col-6 on larger screens) -->
                            <div class="col-12 col-lg-6 mb-4 mb-lg-0">

                                <img src="assets/img/case-study/dr-axico.jpg" class="" height="350" alt="Image">
                            </div>

                            <!-- Content on Right (col-12 on mobile, col-6 on larger screens) -->
                            <div class="col-12 col-lg-6 py-3 px-3 d-flex align-items-center subharti-bg">
                                <div class="py-3 text-center">
                                    <span class="fw-bold case-study">Case Study</span>
                                    <h2 class="fw-bolder font-56 mt-4">Dr. Axico Ayurveda</h2>
                                    <p class="my-4 fs-6 f-20 justify-text">
                                        Dr. Axico is a well-known
                                        healthcare center based in Delhi. The renowned medical facility focuses on
                                        Ayurveda
                                        and Traditional Indian methods to overcome numerous diseases by using herbs and
                                        therapies. </p>
                                    <button class="custom-btn btn-12" [routerLink]="'dr-axico'">
                                        <span>Click!</span><span>Read More</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <!-- <div class="row  ">
                 <div class="col-lg-6 h-60  px-0">
                     <div class=" ">
                         <img src="assets/img/case-study/sgvui.png" class="mx-auto" height="350" alt="Image">
                     </div>
                     <div class="d-flex align-items-center py-4 px-3 sgvu-bg">
                         <div class="py-3 px-3">
                             <span class=" fw-bold case-study">Case Study</span>
                             <div class="py-4  ">
                                 <h2 class="fw-bolder font-56">
                                     Suresh Gyan Vihar University
                                 </h2>
                                 <p class="my-4 fs-6 f-20 justify-text">Suresh Gyan Vihar University is one of
                                     the most reputable and preferred universities for not only traditional learning but
                                     also
                                     for online and distance learning courses.

                                 </p>

                                 <button class="custom-btn btn-12" [routerLink]="'suresh-gyan-vihar-university'"><span>Click!</span><span>Read More</span></button>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div class="col-lg-6 h-60 d-flex flex-lg-column flex-column-reverse px-0">
                     <div class="d-flex align-items-center py-4 px-3 dr-axico-bg">
                         <div class="py-3 px-3">
                             <span class=" fw-bold case-study">Case Study</span>
                             <div class="py-4  ">
                                 <h2 class="fw-bolder font-56">
                                      Dr. Axico Ayurveda
                                 </h2>
                                 <p class="my-4 fs-6 f-20 justify-text">Dr. Axico is a well-known
                                     healthcare center based in Delhi. The renowned medical facility focuses on Ayurveda
                                     and Traditional Indian methods to overcome numerous diseases by using herbs and
                                     therapies.

                                 </p>
                                 <button class="custom-btn btn-12" [routerLink]="'dr-axico'"><span>Click!</span><span>Read More</span></button>
                             </div>
                         </div>
                     </div>
                     <div class="bg-beige ">
                         <img src="assets/img/case-study/dr-axico.jpg" class="" height="350" alt="Image">
                     </div>
                 </div>

             </div>-->
        </div>
    </div>


</section>

<div class="container-fluid mt-5">


    <div class="container-fluid mt-5 py-5"
         style="background-image: url('/assets/img/landing-new/footer/footer-bg.png') ; background-size: cover">
        <div class="container">
            <div class="row text-center ">
                <div class=" mb-3 mb-md-0">
                    <h2 class="text-white fw-bold">Start Your Project</h2>
                    <p  class="text-white w-75 mx-auto my-3">
                        If your university or business facing the issue alike and juggling to manage their overall
                        workflow, then get in touch with us! We will provide the customized solution as per your
                        business requirement to help you succeed in your goals.
                    </p>

                </div>
                <div class="  ">
                    <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold"
                            (click)="goTo('/contact')">
                        Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


