<section class="banner">
    <div class="container-fluid bg mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-lg-6 col-12 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56">
                            <span class="brand-primary-text-color">UMS</span>
                            An Ultimate Solution To Manage Universities
                        </h1>
                        <p class="my-4 fs-6 text-secondary f-20 justify-text">Volmint's University Management System
                            (UMS) is an
                            all-encompassing software
                            solution crafted to effortlessly streamline and optimize your university’s operations. From
                            managing student and teacher attendance, tracking admissions and enrollment, to overseeing
                            examination processes and financial integrations all with precision and efficiency.
                        </p>
                        <button class="btn bg-white border-0 shadow-lg px-5 rounded-0 fw-bold hover-effect"
                                (click)="openModal()">View Demo
                            <i
                                class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div
                    class="col-lg-6 col-12 d-flex justify-content-end py-5 d-flex justify-content-center overflow-hidden">
                    <div class="position-relative">
                        <img src="assets/img/landing-new/thumbnail/ums-thumbnail.jpg"
                             class="img-fluid rounded-4" alt="Banner Image" id="video-thumbnail">
                        <button class="play-button position-absolute" (click)="playVideo()"></button>
                        <video #videoElement class="d-none rounded-4" controls>
                            <source src="assets/img/landing-new/ums/ums.webm" type="video/webm">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row g-5">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">Simplify University Operations with Our
                    <span class="brand-primary-text-color"> Powerful UMS</span></h2>
                <p class="my-2 fs-6 text-secondary text-wrap justify-text">The University Management System
                    (UMS) is an all-inclusive
                    software program created
                    to simplify and expedite all facets of university administration. Combines power with
                    user-friendliness, making it the ideal tool for managing your institution efficiently and
                    effectively.
                </p>
                <div class="d-flex justify-content-between w-75 flex-wrap g-3">
                    <div class="fw-bold my-2 px-5 py-3 brand-primary-bg text-white fixed-width-span"
                         *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>
            </div>
            <div class="col-md-6 pt-5 text-right mt-md-5">
                <img src="assets/img/project/ums-2.png" class="mt-2" alt="Image">
            </div>
        </div>
        <div class="row py-5">
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/project/ums-3.png" class="w-75" alt="Image">
            </div>
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Key Features</span> of University Management System</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">The University Management System is
                    crafted to simplify
                    complex processes and
                    enhance overall management. It offers a robust set of features that empower universities to run
                    smoothly and manage institution operations effectively.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-0 my-2 "
                         *ngFor="let item of features">
                        <span>
                            <img src="{{item.icon}}" alt="Image" class="img-fluid">
                        </span>
                        <span class="ms-2 text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <div class="row ">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">Explore Our Comprehensive
                    <span class="brand-primary-text-color">UMS Modules</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Volmint’s UMS is designed to cover
                    every facet of
                    university operations and offers
                    specialized modules to streamline university operations, enhance efficiency, and provide a seamless
                    experience for administrators, faculty, and students.
                </p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="goTo('/contact')">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5  text-right">
                <img src="assets/img/project/ums-4.png" class="mt-2" alt="Image">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid brand-primary-bg">
    <div class="container py-5">
        <h1 class="text-white text-center mt-5 fw-bold">What Makes Our University Management System Stand Out</h1>
        <p class="text-white text-center text-wrap justify-text">Our state-of-the-art university management
            software is a
            ground-breaking tool
            that completely changes how universities operate. The software takes a complete strategy to centralize data
            management and student management, simplify administrative processes, and promote teamwork among staff,
            instructors, and students.
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h4 class="text-white my-4 fw-bold">{{ item.header }}</h4>
                <p class="text-white text-wrap justify-text">{{
                        item.text
                    }}.</p>
            </div>

        </div>
    </div>
</div>

<div class="container-fluid mt-md-5">
    <div class="container">
        <h2 class=" fs-1 fw-bolder text-center">
            <span class="brand-primary-text-color">Exclusive Benefits</span> for You</h2>
        <div class="row py-5 justify-content-between gy-4">
            <div class="col-md-4">
                <div class="card border-0 shadow rounded-4 p-4 bg-white p-4">
                    <h4 class="fw-bold">Admission Management Module (AMM)</h4>
                    <p class="text-secondary text-wrap justify-text">The AMM streamlines admission processes,
                        including marking,
                        lateral entries, credit transfers,
                        student re-registration, follow-ups, reminders, and multiple admissions. It integrates social
                        media leads, manages multiple branches, and offers accounting and reporting panels.
                    </p>
                </div>

            </div>
            <div class="col-md-4">
                <div class="card border-0 shadow rounded-4 p-4 bg-white p-4">
                    <h4 class="fw-bold">Student Management Module (SMM)</h4>
                    <p class="text-secondary text-wrap justify-text">The SMM provides a comprehensive and robust
                        learning management
                        system, including advanced chatbot integrations, streamlined admission forms, social media
                        integration, real-time notifications, customizable reminders, interactive video sessions, live
                        lectures, personalized support services, and detailed examination and documentation management
                        for students.
                    </p>
                </div>

            </div>
            <div class="col-md-4">
                <div class="card border-0 shadow rounded-4 p-4 bg-white p-4">
                    <h4 class="fw-bold">Management Administrative Module (MAM)</h4>
                    <p class="text-secondary text-wrap justify-text"> The MAM system manages admission centers,
                        sessions, student
                        lead inquiries, enrollment ID
                        generation, accounting, reporting, Learning Management System, EMS, EMS, online/offline
                        examinations, email notifications, question paper upload, DMS, and support services.
                    </p>
                </div>
            </div>
        </div>
        <div class="row py-5 gx-5">
            <div class="col-md-7 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Free Enhancements </span> from Volmint to Optimize Your UMS
                    Experience
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">Volmint Technology is on a mission to
                    Empower the future
                    with the essence of
                    Technology, we are working to streamline the education system with the ease of innovation. Following
                    our mission and vision, we are providing complimentary offerings to our clients and customers that
                    enhance user experience and efficiency.
                </p>
                <div class="row g-3 mt-5">
                    <div class="col-sm-6 col-12 px-0 my-3 "
                         *ngFor="let item of offerings">
                        <span>
                            <img src="assets/img/project/check.png" alt="Image" class="img-fluid">
                        </span>
                        <span class="ms-2 text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>

            </div>
            <div class="col-md-5 pt-5 d-flex justify-content-end">
                <img src="assets/img/project/ums-5.png" class="img-fluid " alt="">
            </div>
        </div>
        <!--        <div class="row py-5 gx-5">
                    <div class="col-md-6 py-5">
                        <h2 class=" fs-1 fw-bolder">
                            What Our Customers Says?
                        </h2>
                        <div class="card rounded-4 g-3 mt-5 p-5">
                            <div id="carouselExampleIndicators" class="carousel  slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active mb-5">
                                        <div class="d-flex border-bottom pb-4   justify-content-between align-items-center">
                                            <div class="d-flex w-50 ">
                                                <img src="assets/img/landing-new/reviews/ums-review1.png" alt="Image"
                                                     class="rounded-circle w-25">
                                                <span class="ms-4 mt-2">
                                                     <h4>Mohit Sharma</h4>
                                                     <span>Noida</span>
                                                </span>
                                            </div>
                                            <div>
                                                <img src="assets/img/project/comma.png" alt="Image">
                                            </div>
                                        </div>
                                        <p class="text-secondary text-wrap fw-bold justify-text">We've been using Volmint's UMS
                                            for a year
                                            now, and it has revolutionized our approach
                                            to education. The wide range of tools and resources provided by the platform has
                                            significantly enriched our learning experience.
                                        </p>
                                    </div>
                                    <div class="carousel-item mb-5">
                                        <div class="d-flex border-bottom pb-4 justify-content-between align-items-center">
                                            <div class="d-flex w-50 ">
                                                <img src="assets/img/landing-new/reviews/ums-review2.avif" alt="Image"
                                                     class="rounded-circle w-25">
                                                <span class="ms-4 mt-2">
                                                     <h4>Hari Kumar</h4>
                                                     <span>Delhi</span>
                                                </span>
                                            </div>
                                            <div>
                                                <img src="assets/img/project/comma.png" alt="Image">
                                            </div>
                                        </div>
                                        <p class="text-secondary text-wrap fw-bold justify-text">Their University Management
                                            System is an
                                            all-in-one solution for enhancing online
                                            learning. It not only boosts efficiency but also greatly improves communication
                                            between students and teachers.
                                        </p>
                                    </div>
                                    <div class="carousel-item mb-5">
                                        <div class="d-flex border-bottom pb-4 justify-content-between align-items-center">
                                            <div class="d-flex w-50 ">
                                                <img src="assets/img/landing-new/reviews/ums-review3.avif" alt="Image"
                                                     class="rounded-circle w-25">
                                                <span class="ms-4 mt-2">
                                                     <h4>Manisha</h4>
                                                     <span>Gurugram</span>
                                                </span>
                                            </div>
                                            <div>
                                                <img src="assets/img/project/comma.png" alt="Image">
                                            </div>
                                        </div>
                                        <p class="text-secondary text-wrap fw-bold justify-text">The UMS software has
                                            transformed our
                                            administrative tasks. Its intuitive interface
                                            has made managing class schedules, grades, and admissions incredibly straightforward
                                            and efficient.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <a class="carousel-control-prev bg-purple rounded-circle h-w-40 bottom-0"
                               href="#carouselExampleIndicators" role="button"
                               data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </a>
                            <a class="carousel-control-next bg-purple  rounded-circle h-w-40" href="#carouselExampleIndicators"
                               role="button"
                               data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden text-black">Next</span>
                            </a>

                        </div>
                        <div class="card bg-color-blue py-5 mt-4">
                            <p class="fs-1 fw-bold text-center color-white pt-4">Any Questions?</p>
                            <div class="d-flex justify-content-center py-5">
                                <button class="btn bg-white rounded-0 py-3 fs-5 text-black w-50 rounded-4"
                                        (click)="goTo('/contact')">Contact Us
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 pt-5">

                        <div class=" w-100 ps-md-5">
                            <h2 class=" fs-1 fw-bolder">
                                Book Appointment Now!
                            </h2>
                            <div *ngIf="successMessage" class="alert alert-success my-2 fw-bold">
                                {{ successMessage }}
                            </div>
                            <form [formGroup]="contactForm" action="" class="mt-5 w-100 rounded-4 px-3 py-4 p-md-5"
                                  style="background: #F4F4F4">
                                <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                                    <input type="name" class="form-control" id="name" formControlName="name"
                                           placeholder="Enter Your Name">
                                    <label for="name">Name</label>
                                    <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                                    <input type="email" class="form-control" id="email" formControlName="email"
                                           placeholder="name@example.com">
                                    <label for="email">Email</label>
                                    <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                                    <input type="number" class="form-control" id="contact" formControlName="contact"
                                           placeholder="Enter Your Phone Number">
                                    <label for="contact">Phone Number</label>
                                    <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                                    <input type="text" class="form-control" id="subject" formControlName="subject"
                                           placeholder="Subject">
                                    <label for="subject">Subject</label>
                                    <small *ngIf="submitted && contactForm.get('subject').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>
                                <div class="form-floating mb-3 rounded-3 outline-0 shadow border-0">
                                    <textarea class="form-control" placeholder="Leave a message here" id="message"
                                              style="resize: none;"
                                              formControlName="message"></textarea>
                                    <label for="message">Message</label>
                                    <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                           class="text-danger">
                                        Field is required.
                                    </small>
                                </div>

                                <button class="btn rounded-pill brand-primary-bg text-white mt-5 w-100 hover-white"
                                        (click)="submit()">Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>-->
    </div>
</div>

<div class="container-fluid mt-md-4 pb-md-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="Image" class="img-fluid"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="primary-blue fw-bold">Email: <a href="mailto:support@volmint.com"
                                                              class="text-decoration-underline primary-blue">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="bg-color-blue border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #5236FF;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;  What makes Volmint's UMS user-friendly?</span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our UMS features an intuitive interface designed for easy navigation, making it simple
                                for administrators, faculty, and students to manage daily operations without any
                                technical hurdles.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;How does your UMS ensure data security?</span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We prioritize data security with role-based access and advanced authentication
                                protocols, ensuring that sensitive information is protected across both web and mobile
                                platforms.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;   Can Volmint’s UMS be customized for specific university needs?</span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, our UMS is highly customizable, allowing you to tailor modules and features to meet
                                your institution’s unique requirements, ensuring a perfect fit for your operations.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;  How does Volmint's UMS streamline the admission process?</span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our UMS automates the entire admission workflow, from application submission to
                                enrollment, making the process faster, more efficient, and error-free for both
                                administrators and students.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What support does Volmint Technologies offer after UMS implementation?</span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                We provide 24/7 dedicated support to ensure your university’s operations remain
                                uninterrupted. Our team is always available to assist with any issues or inquiries.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 ums-bottom-bg ps-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10">
                <h1 class="text-white">Ready to elevate your university management to next level?</h1>
            </div>
            <div class="col-md-2">
                <button class="btn bg-white rounded-0 px-5 py-3 fw-bold text-black hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
