<div class="container-fluid bg mt-5">
    <div class="container mt-5 pt-5">
        <div class="row g-5">
            <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                <div>
                    <h1 class="fw-bolder font-56 color-white">
                        E-Clinic Management Solution - Revolutionize Patient Care and Workflow
                    </h1>
                    <p class="my-5 fs-5 color-white fw-bold justify-text">Managing your clinic is made easy with E-Clinic Solution.
                        The
                        software helps you spend less time on administrative tasks, whether you are a big healthcare
                        chain or an individual practitioner. By having everything you require in one place, you can
                        improve patient satisfaction and expand your clientele.
                    </p>
                </div>
            </div>

            <div class="col-md-6 col-12 d-flex justify-content-end">
                <div class="card custom-card p-4">
                    <div class="">
                        <p class="card-title text-center fw-bold fs-4">Book Your Appointment Now!</p>
                    </div>
                    <div *ngIf="successMessage" class="alert alert-success my-2 fw-bold">
                        {{ successMessage }}
                    </div>
                    <div class="card-body">
                        <form [formGroup]="contactForm" class="px-4 py-2">
                            <div class="mb-2">
                                <input type="name" class="form-control rounded-2 p-3 fw-bold shadow-lg" id="name"
                                       formControlName="name"
                                       placeholder="Enter Your Name" required>
                                <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="mb-2">
                                <input type="email" class="form-control rounded-2 p-3 fw-bold shadow-lg" id="email"
                                       formControlName="email"
                                       placeholder="Enter Your Email" required>
                                <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="mb-2">
                                <input type="tel" class="form-control rounded-2 p-3 fw-bold shadow-lg" id="contact"
                                       formControlName="contact"
                                       placeholder="Enter Your Phone No." required>
                                <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="mb-2">
                                <input type="text" id="subject" class="form-control rounded-2 p-3 fw-bold shadow-lg"
                                       placeholder="Subject"
                                       formControlName="subject" required>
                                <small *ngIf="submitted && contactForm.get('subject').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="mb-2">
                                <textarea class="form-control rounded-2 p-3 fw-bold shadow-lg" id="message" style="resize: none;"
                                          formControlName="message"
                                          rows="5"
                                          placeholder="Your Message" required></textarea>
                                <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                       class="text-danger">
                                    Field is required.
                                </small>
                            </div>
                            <div class="text-center mt-2">
                                <button type="submit" class="btn bg-green color-white fw-bold fs-6"
                                        (click)="submit()">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container pt-5">
        <div class="row g-3 g-sm-1 g-md-5">
            <div class="col-md-6 col-12">
                <img src="assets/img/landing-new/eclinic/eclinic1.png" alt="Image" class="img-fluid"/>
            </div>
            <div class="col-md-6 col-12 pb-0">
                <h2 class="fs-1 fw-bolder">Book on same or next-day your appointments</h2>
                <p class="my-4 fs-6 text-secondary justify-text">With our E-Clinic Management Software, scheduling your appointments
                    has never been easier. Enjoy the flexibility of booking consultations on the same or next day,
                    ensuring prompt care when you need it most.
                </p>
                <div class="row g-3 mt-3 ms-2">
                    <div class="col-12 px-0" *ngFor="let item of appointments">
                        <span>
                            <img src="{{item.image}}" alt="Image" class="img-fluid">
                        </span>
                        <span class="ms-2 text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                    <div class="col-12">
                        <button class="btn px-5 rounded-4 bg-light-blue mt-2" (click)="goTo('/contact')">Make an
                            appointment <i
                                class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 mt-md-0">
    <div class="container">
        <h2 class="fs-1 fw-bolder text-md-center">Features Designed to Implement Your Clinic’s Success</h2>
        <div class="row g-4 mt-4">
            <div class="col-12 col-md-4 mb-4" *ngFor="let item of features">
                <div class="card p-4 pt-4 rounded-4 fixed-width border-0 shadow-lg">
                    <div class="d-flex justify-content-start ps-2">
                        <img src="{{item.image}}" alt="Image" class="img-fluid w-25"/>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title fs-5 fw-bold">{{ item.text }}</h5>
                        <p class="card-text  text-secondary text-wrap justify-text">{{ item.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container pt-5">
        <div class="row g-5">
            <div class="col-md-6 col-12">
                <img src="assets/img/landing-new/eclinic/eclinic3.png" alt="Image" class="img-fluid"/>
            </div>
            <div class="col-md-6 col-12">
                <h2 class=" fs-1 fw-bolder">We Provide Full Consultation Solution</h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">Experience a seamless consultation process with our
                    E-Clinic Management Software. We offer a comprehensive solution that simplifies scheduling, enhances
                    patient interactions, and manages follow-ups—all from a single platform. Streamline your workflow,
                    reduce administrative burdens, and provide your patients with efficient, top-notch care every time
                    they visit.
                </p>
                <div class="row g-3 mt-5">
                    <div class="col-12">
                        <button class="btn px-5 rounded-4 bg-light-blue mt-2 mt-md-5" (click)="goTo('/contact')">Make an
                            appointment <i
                                class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 pb-md-5">
    <div class="container ">
        <div class="row g-4 mt-5 mt-md-0">
            <div class="col-md-6 col-12">
                <h2 class=" fs-1 fw-bolder">We Bring Fully Digital Solutions for Your Clinic Needs!</h2>
                <p class="my-4 fs-6 text-secondary justify-text">Transform your clinic’s operations with our all-in-one digital
                    solution. Our E-Clinic Management Software is designed to streamline your workflow, enhance patient
                    care, and simplify administrative tasks—all with the power of cutting-edge technology.
                </p>
                <div class="row g-3">
                    <div class="col-12 px-0"
                         *ngFor="let item of solutions">
                        <span>
                            <img src="assets/img/landing-new/eclinic/eclinic4.1.png" alt="Image" class="img-fluid">
                        </span>
                        <span class="ms-2 text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <img src="assets/img/landing-new/eclinic/eclinic4.png" alt="Image" class="img-fluid"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 bg-eclinic">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <p class="fs-1 fw-bold my-4">Get Your e-Clinic Solution Now!</p>
                <button class="btn bg-white color-green  px-5 rounded-4 my-5" (click)="openModal()">Contact Expert
                    Now!
                </button>
                <ng-template #contactFormModal>
                    <app-contact-form [isModalOpen]="isModalOpen"
                                      (closeModal)="closeModal()"></app-contact-form>
                </ng-template>
                <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                    <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-end d-none d-sm-block">
                <img src="assets/img/landing-new/eclinic/eclinic5.png" alt="Image" class="img-fluid"/>
            </div>
        </div>
    </div>
</div>

<section class="few-steps">
    <div class="container-fluid mt-50px pt-md-5">
        <div class="container">
            <h2 class="text-md-center fs-1 fw-bolder">Few Easy Steps To Give Consultation</h2>
            <div class="row g-5 mt-md-5">
                <div class="col-md-6 col-12 timeline-section d-none d-md-block">
                    <div *ngFor="let step of timeline; let i = index" class="timeline-item"
                         [ngClass]="{'active': i === currentIndex}"
                         (click)="changeStep(i)">
                        <div class="timeline-content">
                            <div class="timeline-number">{{ step }}</div>
                            <div class="timeline-text">{{ texts[i] }}</div>
                        </div>
                        <div class="timeline-description justify-text" *ngIf="i === currentIndex">
                            {{ descriptions[i] }}
                        </div>
                    </div>
                    <div class="">
                        <button class="btn rounded-4 bg-light-blue mt-5" (click)="goTo('/contact')">Make an
                            appointment
                        </button>
                    </div>
                </div>
                <div class="col-md-6 col-12 image-section d-none d-md-block">
                    <img [src]="images[currentIndex]" alt="Displayed Image" class="img-fluid"/>
                </div>
                <div class="row g-4 d-block d-md-none">
                    <div class="col-12 col-md-4 mb-3">
                        <div class="card" (click)="toggleCard(1)">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="step-number">1</div>
                                    <div class="step-text fs-6 fw-bold" [ngClass]="{'active-text': openCard === 1}">
                                        Login & Enter Basic Details
                                    </div>
                                </div>
                                <div class="collapse-content" [ngClass]="{'show': openCard === 1}">
                                    <p class="description-text text-secondary justify-text">Registered yourself by creating an
                                        account following some basic details. Login with your unique credentials and
                                        proceed further.</p>
                                    <img src="assets/img/landing-new/eclinic/eclinic6.1.png" alt="Image 1"
                                         class="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <div class="card" (click)="toggleCard(2)">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="step-number">2</div>
                                    <div class="step-text fs-6 fw-bold" [ngClass]="{'active-text': openCard === 2}">
                                        Define Your Issues
                                    </div>
                                </div>
                                <div class="collapse-content" [ngClass]="{'show': openCard === 2}">
                                    <p class="description-text text-secondary justify-text"> Elaborate about your issues and medical
                                        history if necessary to get a proper and relevant consultation along with an
                                        expert’s prescription.</p>
                                    <img src="assets/img/landing-new/eclinic/eclinic6.2.png" alt="Image 2"
                                         class="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <div class="card" (click)="toggleCard(3)">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="step-number">3</div>
                                    <div class="step-text fs-6 fw-bold" [ngClass]="{'active-text': openCard === 3}">Pay
                                        For Consultation Online / Offline
                                    </div>
                                </div>
                                <div class="collapse-content" [ngClass]="{'show': openCard === 3}">
                                    <p class="description-text text-secondary justify-text">Pay the consultation fee online using
                                        various payment methods available or you can choose to pay offline also.</p>
                                    <img src="assets/img/landing-new/eclinic/eclinic6.3.png" alt="Image 3"
                                         class="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="difference">
    <div class="container-fluid mt-5 bg-green py-5">
        <div class="container">
            <h2 class="fs-1 fw-bolder text-center color-white">Why We Are Different?</h2>
            <div class="row g-4 mt-4">
                <div class="col-12 col-md-3 mb-4" *ngFor="let item of different">
                    <div class="card p-3 rounded-4">
                        <div class="d-flex justify-content-center">
                            <img src="assets/img/landing-new/eclinic/eclinic2.png" alt="Image" class="img-fluid"/>
                        </div>
                        <div class="card-body">
                            <h5 class="card-title text-center fs-5 fw-bold">{{ item.text }}</h5>
                            <p class="card-text text-center text-secondary text-wrap justify-text">{{ item.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<div class="container-fluid mt-5 pt-md-5 pb-md-5">
    <div class="container">
        <h2 class="fs-1 fw-bolder text-center">What Our Clients Say?</h2>
        <div class="row g-4 mt-4">
            <div class="col-12 col-md-4 mb-4" *ngFor="let item of clients">
                <div class="card p-3 rounded-4 border-0 shadow-lg">
                    <div class="row">
                        <div class="col-3">
                            <img src="{{item.img}}" alt="{{item.name}}" class="img-fluid"/>
                        </div>
                        <div class="col-6">
                            <p class="fs-6 fw-bold">
                                {{ item.name }} <br/>
                                <span class="text-secondary custom-font-size">{{ item.designation }}</span>
                            </p>
                        </div>
                        <div class="col-3">
                            <img src="assets/img/landing-new/eclinic/common-client.png" alt="Image" class="img-fluid"/>
                        </div>
                    </div>
                    <div class="card-body">
                        <p class="card-text text-secondary text-wrap fw-bold justify-text">{{ item.description }}</p>
                        <div class="star-rating">
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                            <i class="fa fa-star" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="Image" class="img-fluid"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="color-green fw-bold">Email: <a href="mailto:support@volmint.com" class="text-decoration-underline color-green">support@volmint.com</a></p>
                    <button type="submit" class="bg-green border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #04726C;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; How does the E-Clinic Management Software improve patient care?</span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our software simplifies administrative tasks, allowing you to focus more on patient
                                care. Features like easy appointment scheduling, comprehensive patient dashboards, and
                                e-prescriptions enhance the overall patient experience and streamline care delivery.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #04726C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Can I manage appointments for the same day or the next day?</span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, our system allows for quick scheduling, letting you book consultations for the same
                                day or the next day, ensuring prompt and flexible patient care.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #04726C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What types of billing does the software support?</span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The software handles various billing needs, including consultations, treatment plans,
                                and outsourced labs. It ensures accuracy and transparency in billing processes, reducing
                                errors and disputes.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #04726C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Is the software easy to use for clinic staff and patients?</span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Absolutely! Our user-friendly interface is designed to be intuitive for both clinic
                                staff and patients, making administrative tasks and appointment management
                                straightforward and efficient.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #04726C;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; How does the system support clinic staff management?</span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Our software includes robust staff management features, allowing you to schedule shifts,
                                monitor performance, and facilitate communication, ensuring smooth operations and
                                effective team coordination.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid eclinic-bottom-bg ps-md-5 mt-5">
    <div class="container-fluid eclinic-bottom-bg ps-md-5 mt-5">
        <div class="container">
            <div class="row align-items-center py-5">
                <div class="col-md-10 col-12">
                    <h1 class="text-white">Over 300+ peoples are daily visit our medical center.</h1>
                </div>
                <div class="col-md-2 col-12">
                    <button class="fw-bold px-5 py-3 border-0 hover-effect"
                            (click)="goTo('/contact')">Contact Us
                    </button>
                </div>
            </div>
        </div>
    </div>
