import {Component, OnInit} from '@angular/core';
import {BlogService} from '../../../shared/services/blog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {error, logging} from 'protractor';
import {Meta, Title} from '@angular/platform-browser';

@Component({
    selector: 'app-blog-detail',
    templateUrl: './blog-detail.component.html',
    styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {
    title: any;
    id: any;
    selectedData: any;
    slug: any;
    headings: any[] = []; // Array to hold headings and their corresponding indices
    contentSections: any[] = [];

    paragraphs: string[] = [];
    contentData: { heading: string | null; paragraphs: string[] }[] = [];

    activeTab = 0;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private blogServices: BlogService,
                private meta: Meta,
                private titles: Title) {
        this.route.paramMap.subscribe(params => {
            this.slug = params.get('blogName');
            this.getByTitle();
        });
        if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras != null && this.router.getCurrentNavigation().extras.state != null) {
            this.id = this.router.getCurrentNavigation().extras.state.id;
            console.log(this.id);
        }
    }

    ngOnInit(): void {

        this.getByTitle();

    }



    goTo(url) {
        this.router.navigateByUrl(url);
    }

    getByTitle() {
        this.blogServices.getByTitle(this.slug).subscribe(data => {
            this.selectedData = data;
            this.updateMetaTags();
            this.extractHeadingsAndContent();
           // this.extractHeadingsAndDescriptions();
        });
    }

    selectTab(index: number): void {
        this.activeTab = index;
    }

    updateMetaTags() {
        this.titles.setTitle(this.selectedData.meta_title || this.selectedData.heading);
        this.meta.updateTag({
            name: 'description',
            content: `${this.selectedData.meta_description || this.selectedData.heading}`
        });
        this.meta.updateTag({name: 'keyword', content: `${this.selectedData.meta_keyword || 'volminttechnologies'}`});
    }
    extractHeadingsAndContent() {
        // Parsing the HTML content safely
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.selectedData.content, 'text/html');

        // Querying all the heading tags (h1, h2, h3, etc.)
        const headingTags = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');

        // Clear the previous content to avoid duplication
        this.headings = [];
        this.contentSections = [];

        headingTags.forEach((heading, index) => {
            const tagName = heading.tagName.toLowerCase();
            const headingText = heading.textContent;

            // Initialize a container for the content that follows the heading
            let content = '';
            let sibling = heading.nextElementSibling;

            // Loop to get all content until the next heading tag is encountered
            while (sibling && !/^h[1-6]$/i.test(sibling.tagName)) {
                content += sibling.outerHTML || sibling.textContent;
                sibling = sibling.nextElementSibling;
            }

            // Generate a unique ID for the section (e.g., "section-1", "section-2", etc.)
            const sectionId = 'section-' + index;

            // Add the extracted heading and content to the respective arrays
            this.headings.push({ tag: tagName, text: headingText, index, id: sectionId });
            this.contentSections.push({ tag: tagName, headingText, content, index, id: sectionId });
        });
        console.log(this.contentSections);
    }

    // Method to scroll to a section based on the clicked heading
    scrollToSection(index: number): void {
        const element = document.getElementById('section-' + index);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }






}
