<section class="wave-section">
    <div class=" py-5 bg-sea-green ">
        <div class="container">
            <div class="row flex-column-reverse flex-md-row ">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1>
                            <span  class="fw-bolder font-56 d-none d-md-block">
                                <span class="text-primary">Expand Your Digital Footprint</span>
                                     with the Best SEO Services
                            </span>
                            <span class="fw-bolder fs-3 d-md-none">
                                <span
                                    class="text-primary">Expand Your Digital Footprint </span>
                            with the Best SEO Services
                            </span>
                        </h1>


                        <p class="my-4 fs-6 text-white text-wrap f-20 justify-text d-none d-md-block">Build up a digital presence with proven search engine optimization strategies to enhance your digital presence, boost brand visibility, and increase engagement with your target audience.
                        </p>
                        <h1 class="fw-bolder fs-3 d-md-none"><span
                            class="text-primary">Expand Your Digital Footprint </span>
                            with the Best SEO Services</h1>
                        <p class=" text-white text-wrap f-20 justify-text d-md-none">Build up a digital presence with proven search engine optimization strategies to enhance your digital presence, boost brand visibility, and increase engagement with your target audience.

                        </p>
                        <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                                (click)="openModal()">Get Started <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 justify-content-end py-md-5 d-flex justify-content-center">
                    <div
                        class=" md:mr-0 position-relative border-0  d-flex justify-content-center border-radius-10">
                        <img src="assets/img/landing-new/seo/intro-seo.png"
                             alt="Image"
                             class="img-fluid border-radius-10 d-none d-md-block"/>
                        <img src="assets/img/landing-new/seo/intro-seo.png"
                             alt="Image"
                             class="img-fluid w-50 mx-auto d-md-none "/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wave">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="white" fill-opacity="1"
                  d="M0,32L17.1,53.3C34.3,75,69,117,103,144C137.1,171,171,181,206,197.3C240,213,274,235,309,224C342.9,213,377,171,411,154.7C445.7,139,480,149,514,138.7C548.6,128,583,96,617,69.3C651.4,43,686,21,720,32C754.3,43,789,85,823,128C857.1,171,891,213,926,213.3C960,213,994,171,1029,154.7C1062.9,139,1097,149,1131,144C1165.7,139,1200,117,1234,122.7C1268.6,128,1303,160,1337,192C1371.4,224,1406,256,1423,272L1440,288L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path>
        </svg>
    </div>
</section>
<section class="" style="overflow-x:hidden;">
    <div class="container pb-4">
        <div class="row g-5 justify-content-center">
            <div class=" align-self-center">
                <div class=" p-3 md-mt-5 h-100">
                    <h2 class="text-primary md-mt-5 fw-bold">Why Businesses Struggle Without SEO</h2>
                    <p class=" fs-6 text-secondary text-wrap justify-text">Not having a solid SEO strategy can severely
                        limit your business's growth and visibility. SEO is no longer optional—it’s essential for
                        staying ahead in a competitive market. Without a reputed SEO company in India, your business
                        faces challenges that can lead to missed opportunities and reduced profitability. Let’s explore
                        the hurdles businesses encounter when they ignore SEO and how Volmint Technologies can help turn
                        the tide in your favor.
                    </p>
                    <div style="height:10px;" class="rounded border w-25 bg-gradient"></div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center">
                <div
                    class="position-relative  pink-box p-3 text-center d-flex align-items-center justify-content-center  ">
                    <div>
                        <img src="assets/img/landing-new/seo/poor-visibility.png" alt="" class="" style="width: 64px;">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class=""> Poor Online Visibility </span>
                        </h3>
                        <p class="text-secondary">
                            SEO is crucial for a website's visibility on search engines, preventing competitors from
                            capturing potential customers and enhancing its search engine ranking.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center">
                <div
                    class="position-relative blue-box p-3 text-center d-flex align-items-center justify-content-center ">
                    <div>
                        <img src="assets/img/landing-new/seo/decrease.png" alt="">

                        <h3 class="fs-5 mt-3 fw-bolder">
                    <span
                        class="">Decrease in Website Traffic  </span></h3>
                        <p class="text-secondary">A website without SEO attracts minimal organic traffic, forcing
                            businesses to rely solely on paid ads or word-of-mouth marketing that reduces long-term
                            sustainability.</p>
                    </div>

                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative p-3 text-center d-flex align-items-center justify-content-center orange-box">
                    <div>
                        <img src="assets/img/landing-new/seo/img.png" alt="" width="75">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">Lack of Credibility</span></h3>
                        <p class="text-secondary">Search engines favor websites that adhere to SEO best practices,
                            making them appear more trustworthy. Without proper SEO, your website risks being perceived
                            as unreliable by users.</p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative p-3 text-center d-flex align-items-center justify-content-center green-box">
                    <div>
                        <img src="assets/img/landing-new/seo/img_1.png" alt="" width="75">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">  Missed Local Opportunities </span></h3>
                        <p class="text-secondary">Ignoring SEO can lead to missed connections with local customers, as
                            it's crucial for businesses targeting specific regions to increase their search
                            visibility.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative yellow-box p-3 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <img src="assets/img/landing-new/seo/img_2.png" alt="" width="75">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">Bad User Experience (UX)  </span></h3>
                        <p class="text-secondary">
                            SEO isn’t just about keywords—it’s also about optimizing site speed, navigation, and mobile
                            responsiveness. Without SEO, users may experience a clunky website that frustrates them.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 align-self-center ">
                <div
                    class="position-relative red-box p-3 text-center d-flex align-items-center justify-content-center">
                    <div>
                        <img src="assets/img/landing-new/seo/disablity.png" alt="">
                        <h3 class=" fs-5 mt-3 fw-bolder">
                     <span
                         class="">Inability to Compete  </span></h3>
                        <p class="text-secondary">
                            Your competitors are likely leveraging SEO to attract customers, leaving you behind in the
                            digital race. Without SEO, it becomes harder to compete effectively in your industry.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<div class="container-fluid pt-4">
    <div class="container rounded-5 mt-5">
        <div class="row ">
            <div class="col-md-5 align-self-md-center text-center">
                <img src="assets/img/landing-new/seo/business-growth-seo.png" alt="" class="img-fluid rounded">
            </div>
            <div class="col-md-7 py-5">
                <h2 class="text-center  fs-1 fw-bolder">
                    <span class="text-primary">About Volmint’s SEO Services</span></h2>
                <p class=" text-secondary " align="justify">The modern age demands a strong online
                    presence for every business that shows its credibility and authenticity. Being one of the
                    fastest-growing SEO providers in India, Volmint understand the growing demand for the digital
                    landscape. Thus, we offer personalized SEO services designed and strategies as per the business to
                    serve long-term success.
                </p>
                <p class="text-secondary" align="justify">
                    We offer comprehensive SEO strategies based on business needs and goals. We make sure that every
                    element of your website is optimized to rank higher on search engines, from technical SEO and link
                    building to keyword research and on-page optimization. Our data-driven strategy helps you find
                    important possibilities, increase the exposure of your website, and attract more targeted visitors.
                </p>
                <p class="text-secondary" align="justify">
                    We are not only improving your rankings, we are helping you to achieve sustainable growth and
                    success in this challenging online world. Let us handle your SEO while you are craving your business
                    credibility.

                </p>
            </div>

        </div>
    </div>
</div>


<!--<div class="container-fluid">-->
<!--    <div class="container py-5">-->
<!--        <div class="row g-5 py-5 justify-content-center ">-->
<!--            <div class="col-md-12 col-lg-8 mb-lg-5 ">-->
<!--                <div class=" text-center p-3 border  h-100">-->
<!--                    &lt;!&ndash;                    <img src="assets/img/landing-new/seo/on-page-seo.png"&ndash;&gt;-->
<!--                    &lt;!&ndash;                         class=" img-fluid border-radius-10  mx-auto" alt="Image"/>&ndash;&gt;-->
<!--                    <h2 class="brand-primary-text-color text-center mt-3 fw-bold">Type Of SEO</h2>-->
<!--                    <p class=" fs-6 text-secondary text-wrap justify-text">On-page optimization is a fundamental aspect-->
<!--                        of effective Search Engine Optimization (SEO) that focuses on enhancing individual web pages to-->
<!--                        rank-->
<!--                        higher and earn more relevant traffic.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-lg-4 mb-lg-5">-->
<!--                <div class="position-relative text-center p-3 border">-->
<!--                    <div class="px-4  bg-white seo-images">-->
<!--                        <img src="assets/img/landing-new/seo/on-page-seo.png"-->
<!--                             class=" img-fluid border-radius-10  mx-auto" alt="Image"/>-->
<!--                    </div>-->
<!--                    <h3 class=" fs-4 mt-3 fw-bolder">-->
<!--                     <span-->
<!--                         class="brand-primary-text-color"> On-Page Optimization </span>-->
<!--                    </h3>-->
<!--                    <p class=" fs-6 text-secondary text-wrap justify-text">On-page optimization is a fundamental aspect-->
<!--                        of effective Search Engine Optimization (SEO) that focuses on enhancing individual web pages to-->
<!--                        rank-->
<!--                        higher and earn more relevant traffic.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-md-6 col-lg-4 mt-lg-5 ">-->
<!--                <div class="position-relative text-center p-3 border h-100">-->
<!--                    <div class="px-4  bg-white seo-images">-->
<!--                        <img src="assets/img/landing-new/seo/off-page-seo.png"-->
<!--                             class=" img-fluid border-radius-10  mx-auto" alt="Image"/>-->
<!--                    </div>-->
<!--                    <h3 class="fs-4 mt-3 fw-bolder">-->
<!--                    <span-->
<!--                        class="brand-primary-text-color">Off-Page SEO Strategies </span></h3>-->
<!--                    <p class=" fs-6 text-secondary text-wrap justify-text">Off-page SEO strategies are crucial for-->
<!--                        establishing your website's authority and credibility in the digital landscape.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-md-6 col-lg-4 mt-lg-5 ">-->
<!--                <div class="position-relative text-center p-3 border h-100">-->
<!--                    <div class="px-4  bg-white seo-images">-->
<!--                        <img src="assets/img/landing-new/seo/technical-seo.png"-->
<!--                             class=" img-fluid border-radius-10 " alt="Image"/>-->
<!--                    </div>-->

<!--                    <h3 class=" fs-4 mt-3 fw-bolder">-->
<!--                     <span-->
<!--                         class="brand-primary-text-color">  Technical SEO </span></h3>-->
<!--                    <p class=" fs-6 text-secondary text-wrap justify-text">Technical SEO is a vital component of your-->
<!--                        website’s overall performance, ensuring that search engines can efficiently crawl, index, and-->
<!--                        rank-->
<!--                        your pages.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-md-6 col-lg-4 mt-lg-5 ">-->
<!--                <div class="position-relative text-center p-3 border h-100">-->
<!--                    <div class="px-4  bg-white seo-images">-->
<!--                        <img src="assets/img/landing-new/seo/local-seo.png"-->
<!--                             class="w-100 border-radius-10" alt="">-->
<!--                    </div>-->

<!--                    <h3 class="fs-4 mt-3 fw-bolder">-->
<!--                    <span-->
<!--                        class="brand-primary-text-color">Local SEO Optimization </span>-->
<!--                    </h3>-->
<!--                    <p class=" fs-6 text-secondary text-wrap justify-text">Local SEO optimization is essential for-->
<!--                        businesses looking to attract customers in their geographic area.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<div class="container-fluid my-5">
    <div class="container ">
        <div class="row g-4 pb-4 justify-content-center mx-auto">
            <div class=" align-self-center py-4">
                <h2 class="my-2 fs-1 fw-bolder">
                     <span
                         class="text-primary">Boost Your  Online Presence With Volmint’s SEO Services  </span>
                </h2>
                <p class="text-secondary">Volmint Technologies boosts your online presence with tailored SEO services
                    that drive organic traffic, enhance visibility, and expand your brand's reach. From technical SEO to
                    keyword optimization, we ensure measurable results for businesses of all sizes. </p>


                <!--                <div class="row py-2 border-bottom">-->
                <!--                    <div class="col-lg-2  col-6 align-self-center">-->
                <!--                        <img src="assets/img/landing-new/seo/pay-per-click.png" alt="" class="w-50">-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-4 col-6 align-self-center">-->
                <!--                        <h4 class="fs-6 fw-bold"> Pay-per-click Advertising</h4>-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-6  col-12">-->
                <!--                        <p class="text-secondary">Providing an opportunity for advertising to a specific audience who is-->
                <!--                            actively searching for related information.</p>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="row py-2 border-bottom">-->
                <!--                    <div class="col-lg-2  col-6 align-self-center">-->
                <!--                        <img src="assets/img/landing-new/seo/pay-per-click.png" alt="" class="w-50">-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-4   col-6 align-self-center">-->
                <!--                        <h4 class="fs-6 fw-bold"> Pay-per-click Advertising</h4>-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-6 col-12">-->
                <!--                        <p class="text-secondary">Providing an opportunity for advertising to a specific audience who is-->
                <!--                            actively searching for related information.</p>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="row py-2 border-bottom">-->
                <!--                    <div class="col-lg-2  col-6 align-self-center">-->
                <!--                        <img src="assets/img/landing-new/seo/pay-per-click.png" alt="" class="w-50">-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-4   col-6 align-self-center">-->
                <!--                        <h4 class="fs-6 fw-bold"> Pay-per-click Advertising</h4>-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-6 col-12">-->
                <!--                        <p class="text-secondary">Providing an opportunity for advertising to a specific audience who is-->
                <!--                            actively searching for related information.</p>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="row py-2 border-bottom">-->
                <!--                    <div class="col-lg-2  col-6 align-self-center">-->
                <!--                        <img src="assets/img/landing-new/seo/pay-per-click.png" alt="" class="w-50">-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-4   col-6 align-self-center">-->
                <!--                        <h4 class="fs-6 fw-bold"> Pay-per-click Advertising</h4>-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-6 col-12">-->
                <!--                        <p class="text-secondary">Providing an opportunity for advertising to a specific audience who is-->
                <!--                            actively searching for related information.</p>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                    <p class="my-4 fs-6 text-secondary text-wrap justify-text">Search Engine Optimization (SEO) is a-->
                <!--                        critical component of any successful digital marketing strategy, as it directly impacts your-->
                <!--                        online-->
                <!--                        visibility and customer engagement.-->
                <!--                    </p>-->
                <!--                    <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"-->
                <!--                            (click)="openModal()">Book Appointment-->
                <!--                        <i-->
                <!--                            class="ms-3 fa-solid fa-arrow-right"></i>-->
                <!--                    </button>-->
            </div>
            <div class="container py-5">
                <div class="row g-4">
                    <!-- On-Page SEO Box -->
                    <div class="col-md-6 col-lg-3">
                        <div class="rounded-4 p-3 h-100 shadow-lg bg-white">
                            <img src="assets/img/landing-new/seo/on-page-seo.png" class="img-fluid mx-auto d-block" alt="On-Page SEO">
                            <h4 class="fw-bold mt-3 text-center">On-Page</h4>
                            <p class="text-secondary text-center">
                                With proper on-page SEO strategies, we refine every element- from keyword research to content, page elements, images to internal linking. We make sure that the site is not only search-engine friendly but user-friendly too. Our on-page services set a perfect stage for your audience and SEO alike.
                            </p>
                        </div>
                    </div>

                    <!-- Off-Page SEO Box -->
                    <div class="col-md-6 col-lg-3">
                        <div class="rounded-4 p-3 h-100 shadow-lg bg-white">
                            <img src="assets/img/landing-new/seo/off-page-seo.png" class="img-fluid mx-auto d-block" alt="Off-Page SEO">
                            <h4 class="fw-bold mt-3 text-center">Off-Page</h4>
                            <p class="text-secondary text-center">
                                We believe in delivering beyond the expectation that thrive ultimate success of your website. Through our Off-Page SEO services, we not only build quality backlinks but also monitor and strengthen them. Optimize and improve your brand’s credibility. It's about creating strong connections and leaving an impact across the web.
                            </p>
                        </div>
                    </div>

                    <!-- Local SEO Box -->
                    <div class="col-md-6 col-lg-3">
                        <div class="rounded-4 p-3 h-100 shadow-lg bg-white">
                            <img src="assets/img/landing-new/seo/local-seo.png" class="img-fluid mx-auto d-block" alt="Local SEO">
                            <h4 class="fw-bold mt-3 text-center">Local SEO</h4>
                            <p class="text-secondary text-center">
                                Boost your local popularity and attract the close by customers. With our Local SEO services, we put your business on the map to stay top of mind and of search results in your community. We make sure your local presence by optimizing your Google My Business profile to target location-specific keywords.
                            </p>
                        </div>
                    </div>

                    <!-- Technical SEO Box -->
                    <div class="col-md-6 col-lg-3">
                        <div class="rounded-4 p-3 h-100 shadow-lg bg-white">
                            <img src="assets/img/landing-new/seo/technical-seo.png" class="img-fluid mx-auto d-block" alt="Technical SEO">
                            <h4 class="fw-bold mt-3 text-center">Technical SEO</h4>
                            <p class="text-secondary text-center">
                                A strong foundation is key to your success. Our technical SEO experts handle the nuts and bolts and ensure your site is superfast, secure, and bug-free. The services SEO involves optimizing website and server settings to improve search engine rankings by enhancing technical features to deliver a seamless experience.
                            </p>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </div>
</div>


<!--<div class="container-fluid px-0 bg-gradient" >-->
<div class="container-fluid bg-gradient">
    <div class="container py-5">
        <h2 class="text-white text-center fw-bold">Our Roadmap That Brings Success to Your Website</h2>
        <div class="row gy-5 py-4">
            <div class="col-md-4 text-center" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white fw-bold text-black d-flex align-items-center justify-content-center mx-auto">{{ i + 1 }}</div>
                <h3 class="text-white my-3 fw-bold">{{ item.header }}</h3>
                <p class="text-white text-justify">{{ item.text }}</p>
            </div>
        </div>
    </div>
</div>

<!--</div>-->
<div class="container-fluid">
    <div class="container ">
        <div class="row justify-content-center py-5">
            <h2 class=" fs-1 fw-bolder  text-center">
                     <span
                         class="text-primary">  SEO</span> vs. PPC: Which Works for You?</h2>
            <p class="text-secondary">The right strategy depends on factors like your business goals, timeline, budget,
                and market competition. A reliable marketing agency will craft a customized plan that blends both
                approaches, ensuring they align with your unique needs and drive meaningful results.</p>
            <div
                class="col-md-6  d-flex justify-content-md-end pe-md-5 justify-content-center align-self-center pe-0 py-3  position-relative ">
                <div class="p-0 shadow bg-white w-75 seo py-5">
                    <div
                        class="flag-div d-flex justify-content-between align-items-center ps-5 fs-1 fw-bold text-white">
                        <div class="py-3 ps-3">
                            <p class="title fs-3 text-white me-5">SEO</p>
                        </div>
                        <div class="py-3 pe-2">
                            <i class="fa fa-thumbs-up fs-4 me-3"></i>
                        </div>
                    </div>
                    <h4 class="fw-bold mx-4 t">SEO – Search Engine Optimization</h4>
                    <ul class="list-unstyled px-3">
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Emphasizes organic traffic and search rankings.
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Long-term plan for enduring visibility.
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Increases brand reputation and reach.
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Cost-effective and ideal for long-term goals.

                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Builds a lasting online presence.

                            </p>
                        </li>
                    </ul>

                </div>
            </div>
            <div
                class="col-md-6 d-flex align-self-center justify-content-center justify-content-md-start ps-md-5 ps-0 py-3  position-relative ">
                <div class="p-0 shadow bg-white w-75 ppc py-5">
                    <div
                        class="flag-div d-flex justify-content-between align-items-center pe-2 fs-1 fw-bold text-white">
                        <div class="py-3 pe-2">
                            <p class="title fs-3 text-white me-5">PPC</p>
                        </div>
                        <div class="py-3">
                            <i class="fa fa-thumbs-up fs-4 me-5"></i>
                        </div>
                    </div>
                    <h4 class="fw-bold mx-4">PPC – Pay-Per-Click</h4>
                    <ul class="list-unstyled px-3">
                        <li class="d-flex p-1 align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Delivers instant visibility and quick traffic to the site.
                            </p>
                        </li>
                        <li class="d-flex p-1 align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Perfect for short-term campaigns focused on immediate outcomes.
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Offers precise ad targeting and control over keyword bidding.
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Enables real-time adjustments to optimize campaign performance.
                            </p>
                        </li>
                        <li class="d-flex p-1  align-items-center">
                            <i class="fa-solid fa-circle-check text-gradient fs-4 me-3"></i>
                            <p class="text-secondary">
                                Highly effective for product launches or seasonal promotions.
                            </p>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid ">
    <div class="container">
        <div class="row py-3">
            <div class="col-12 col-md-6 align-self-center">
                <div class="col-12">
                    <img src="assets/img/landing-new/mobile-development/faq.png" alt="Image"
                         class="img-fluid w-50"/>
                    <h2 class="fs-1 fw-bold">Frequently Asked Questions</h2>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="text-primary fw-bold">Email: <a href="mailto:support@volmint.com"
                                                              class="text-decoration-underline text-primary">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4 ">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                          <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                          </div>
                                        </div>
                                        <div class="col-11 text-primary">
What are the key benefits of SEO for my business?                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                SEO benefits any business in numerous ways such as helping to increase website ranking
                                on Google Analytics drive leads and increase sales. Moreover, it improves website
                                credibility, user experience, and brand awareness.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold">
                             <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                 <div class="col-11 text-primary">
How can I determine which type of SEO services is right for my website?
                                 </div>
                             </div>
                            </span>
                            </button>
                        </h3>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Before targeting SEO services, consider your budget. Then, compare SEO packages to
                                ensure they fulfill your requirements. Compare your needs and choose among the many SEO
                                available such as local SEO, technical SEO, Keyword Optimization, Off-Page, and On-Page.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false"
                                    aria-controls="collapseThree">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon float-start">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11 text-primary">
Are you able to conduct an SEO audit for my website?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Absolutely, we have a team of experts who conduct thorough research on your website
                                based on your business and competitors. Additionally, working with the Volmint - An
                                Indian SEO agency, offers you the personalized packages that suit your requirements.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false"
                                    aria-controls="collapseFour">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                                <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11 text-primary">
What’s the difference between on-page, off-page, and technical SEO?                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                <ul>
                                    <li><p>Being one of the fastest-growing SEO services providers in India, it’s our
                                        responsibility to provide the right information to our visitors. While talking
                                        about
                                        On-page SEO, its strategies elements like keyword research, content, images, and
                                        internal linking, ensure search-engine and user-friendly sites, setting a
                                        perfect stage
                                        for audience and SEO.</p></li>
                                    <li><p>Off-page SEO services focus on building quality backlinks, monitoring and
                                        strengthening them, and improving brand credibility, ensuring your website's
                                        ultimate success and impact on the web.</p></li>
                                    <li>
                                        <p>Technical SEO services ensure your site is fast, secure, and bug-free,
                                            optimizing website and server settings to improve search engine rankings and
                                            provide a seamless experience.
                                        </p>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false"
                                    aria-controls="collapseFive">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11 text-primary">
Can local SEO help my business rank in nearby searches?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Of Course, local SEO is the best way to increase your business ranking in nearby
                                searches. This helps to increase visibility on the Google, that drives potential
                                customers from the nearby region.

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h3 class="accordion-header" id="headingSix">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix" aria-expanded="false"
                                    aria-controls="collapseSix">
                                <span class="fs-6 fw-bold">
                                    <div class="row">
                                        <div class="col-1">
                                            <div class="encircled-icon">
                                        <i class="fas fa-question fw-bold"></i>
                                            </div>
                                        </div>
                                        <div class="col-11 text-primary">
How can keyword research improve my website's search visibility?
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </h3>
                        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Keywords plays a crucial role to improve the visibility of your business and content.
                                The right keywords help your content rank on the Google and expend your reach to the
                                right audience. When anyone use the keyword for their search, the algorithm promotes
                                your content to the viewer.

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 p-3 p-lg-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png') ; background-size: cover">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to Take Your Business Growth to the Next Level?
            </div>
            <div class="col-md-6 align-self-center col-12 text-md-end">
                <button class="btn bg-white px-5 rounded-0 border-0 shadow-lg fw-bold hover-effect"
                        (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
