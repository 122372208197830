<section class="banner">
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-bs-interval="4000">
        <ol class="carousel-indicators">
            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"></li>
            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img
                    src="assets/img/landing-new/slider-images/amplifying.png"
                    class="d-block w-100" alt="Slide1">
                <div class="carousel-overlay"></div>
                <div class="carousel-caption d-flex justify-content-center align-items-center">
                    <div class="container-fluid text-center">
                        <p class="fs-2 color-white fw-bold">Amplifying <span class="blinking-text color-primary-blue"> Business </span>
                            Progress Through
                            <span class="blinking-text color-primary-blue">
                                 <span class="letter">Innovation</span>
                            </span>
                        </p>
                        <p class="color-white fs-5 text-wrap fw-bold">Empower your growth with cutting-edge tech and
                            expert-driven software solutions for tomorrow's success.</p>
                        <div class="mt-5">
                            <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold"
                                    (click)="goTo('/about')">Know
                                more <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <img src="assets/img/landing-new/slider-images/cutting.png"
                     class="d-block w-100" alt="Slide2">
                <div class="carousel-overlay"></div>
                <div class="carousel-caption d-flex justify-content-center align-items-center">
                    <div class="container-fluid text-center">
                        <p class="fs-2 color-white fw-bold">Cutting-edge <span class="blinking-text color-primary-blue">Cloud </span>
                            Based
                            <span class="blinking-text color-primary-blue">
                                <span class="letter">Solutions</span>
                            </span>
                        </p>
                        <p class="color-white fs-5 text-wrap fw-bold">Unlock seamless scalability with Volmint's
                            cutting-edge cloud solutions designed to elevate your business.</p>
                        <div class="mt-5">
                            <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold"
                                    (click)="goTo('/about')">Know
                                more <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <img src="assets/img/landing-new/slider-images/optimize.png"
                     class="d-block w-100" alt="Slide3">
                <div class="carousel-overlay"></div>
                <div class="carousel-caption d-flex justify-content-center align-items-center">
                    <div class="container-fluid text-center">
                        <p class="fs-2 color-white fw-bold"><span
                            class="blinking-text color-primary-blue">Optimize</span> Educational
                            <span class="blinking-text color-primary-blue">
                                <span class="letter">Workflows</span>
                            </span>
                        </p>
                        <p class="color-white fs-5 text-wrap fw-bold">Supercharge productivity and eliminate errors with
                            Volmint’s seamless workflow integration for flawless efficiency.</p>
                        <div class="mt-5">
                            <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold"
                                    (click)="goTo('/about')">Know
                                more <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <img src="assets/img/landing-new/slider-images/complete.png"
                     class="d-block w-100" alt="Slide4">
                <div class="carousel-overlay"></div>
                <div class="carousel-caption d-flex justify-content-center align-items-center">
                    <div class="container-fluid text-center">
                        <p class="fs-2 color-white fw-bold"><span
                            class="blinking-text color-primary-blue">Complete </span> Vicinity of Management
                            <span class="blinking-text color-primary-blue">
                               <span class="letter">Software</span>
                          </span>
                        </p>
                        <p class="color-white fs-5 text-wrap fw-bold">Unlock Efficiency with Volmint: The Ultimate
                            All-in-One Management Solution for Streamlined Success.</p>
                        <div class="mt-5">
                            <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 text-shrink hover-effect fw-bold"
                                    (click)="goTo('/about')">Know more
                                <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </a>
    </div>
</section>

<div class="container-fluid mt-5">
    <div class="container pt-5">
        <p class="fs-1 fw-bold text-center">Innovative Problem-Solving for Your Business Needs</p>
    </div>
</div>

<section class="innovative">
    <div class="container-fluid mt-5 pt-5 pb-4">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="card rounded-0">
                        <img src="assets/img/landing-new/home-innovative/innovative-image-one.jpg" class="card-img-top"
                             alt="Image">
                        <div class="card-body">
                            <p class="card-text fs-6 text-wrap text-secondary">Tailored solutions to address
                                specific
                                business needs, utilizing
                                custom-designed software to efficiently and precisely tackle the operational challenges.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="card rounded-0">
                        <img src="assets/img/landing-new/home-innovative/innovative-image-two.jpg" class="card-img-top"
                             alt="Image">
                        <div class="card-body">
                            <p class="card-text fs-6 text-wrap text-secondary">Advanced technology to seamlessly
                                integrate innovative solutions,
                                ensuring your business benefits from the most effective and forward-thinking tools
                                available.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="card rounded-0">
                        <img src="assets/img/landing-new/home-innovative/innovative-image-three.jpg"
                             class="card-img-top"
                             alt="Image 3">
                        <div class="card-body">
                            <p class="card-text fs-6 text-wrap text-secondary">Ensures your business remains
                                agile and
                                competitive in a dynamic
                                market by providing ongoing support and refining solutions based on your feedback and
                                evolving needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid mt-5 p-4 pb-5">
    <div class="container">
        <div class="row g-4">
            <div class="col-12 col-md-6">
                <img src="assets/img/landing-new/landing5.jpg" alt="Image" class="img-fluid h-100 mt-2"/>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column align-items-center align-items-md-start">
                <p class="fs-1 fw-bold text-center text-md-start">Tech-Driven Business Excellence</p>
                <p class="fs-6 text-wrap text-secondary justify-text">Volmint
                    Technologies is committed to fostering transformation and excellence through innovative technology
                    solutions, providing businesses with cutting-edge tools and strategies to meet current demands and
                    anticipate future challenges.</p>
                <div class="row text-center text-md-start">
                    <div class="col-12 col-md-6">
                        <ul class="fs-6" style="list-style: none; padding-left: 0;">
                            <li class="text-secondary">
                                <i class="fa fa-check-circle fa-fa-color rounded-5" aria-hidden="true"></i>
                                Scalable Solutions
                            </li>
                            <li class="pt-3 text-secondary">
                                <i class="fa fa-check-circle fa-fa-color rounded-5" aria-hidden="true"></i>
                                Exceptional Support
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-6">
                        <ul class="fs-6" style="list-style: none; padding-left: 0;">
                            <li class="text-secondary">
                                <i class="fa fa-check-circle fa-fa-color rounded-5" aria-hidden="true"></i>
                                Data-Driven Insights
                            </li>
                            <li class="pt-3 text-secondary">
                                <i class="fa fa-check-circle fa-fa-color rounded-5" aria-hidden="true"></i>
                                Future-Ready Innovations
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="">
                    <button class="mt-md-4 color-white px-5 py-3 bg-color-blue border-0 fs-6 hover-effect fw-bold"
                            (click)="goTo('/about')">Know more <i
                        class="fa fa-arrow-right"
                        aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="video">
    <div class="container-fluid mt-5">
        <div class="container text-center position-relative">
            <p class="fs-1 fw-bold">Your Journey To Technology Starts Here</p>
            <p class="fs-6 text-secondary">At Volmint, we propel your business with cutting-edge tech and expert
                solutions.
            </p>
            <div class="row overflow-hidden">
                <div class="col-12 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div class="position-relative video-container">
                        <img src="assets/img/landing-new/thumbnail/volmint-thumbnail.jpg"
                             class="img-fluid rounded-4" alt="Banner Image" id="video-thumbnail">
                        <button class="play-button position-absolute" (click)="playVideo()"></button>
                        <video #videoElement class="rounded-4" controls>
                            <source src="assets/img/landing-new/volmint-intro.webm" type="video/webm">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="move">
    <div class="container-fluid mt-5 p-5 d-none d-lg-block"
         style="background-image: url('assets/img/landing-new/landing8.png');">
        <div class="container p-3">
            <div class="row">
                <div class="col-md-4">
                    <p class="fs-1 fw-bold mt-5 pt-5 color-white">Your Trusting Partner In Your Business Success</p>
                </div>
                <div class="col-md-4 mt-5">
                    <div class="row">
                        <div class="col-md-4 ">
                            <div class="slider-container">
                                <div class="slider">
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery1.jpeg" alt="Image 1">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery9.jpg" alt="Image 1">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery3.jpeg" alt="Image 3">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery11.jpg" alt="Image 4">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery2.jpeg" alt="Image 2">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery10.jpg" alt="Image 2">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery2.jpeg" alt="Image 3">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery4.jpeg" alt="Image 4">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="slider-container">
                                <div class="slider">
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery9.jpg" alt="Image 1">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery3.jpeg" alt="Image 2">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery10.jpg" alt="Image 3">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery1.jpeg" alt="Image 4">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery11.jpg" alt="Image 1">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery1.jpeg" alt="Image 2">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery3.jpeg" alt="Image 3">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery4.jpeg" alt="Image 4">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="slider-container">
                                <div class="slider">
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery10.jpg" alt="Image 1">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery2.jpeg" alt="Image 2">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery11.jpg" alt="Image 3">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery4.jpeg" alt="Image 4">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery9.jpg" alt="Image 1">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery4.jpeg" alt="Image 2">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery3.jpeg" alt="Image 3">
                                    </div>
                                    <div class="slider-item">
                                        <img src="assets/img/landing-new/gallery/gallery2.jpeg" alt="Image 4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 color-white ps-5">
                    <div class="border-bottom border-top p-4 fs-5" style="width: 80%;">HealthCare</div>
                    <div class="border-bottom p-4 fs-5" style="width: 80%;">Education</div>
                    <div class="border-bottom p-4 fs-5" style="width: 80%;">Technology</div>
                    <div class="border-bottom p-4 fs-5" style="width: 80%;">SaaS & Web3</div>
                    <div class="border-bottom p-4 fs-5" style="width: 80%;">Management</div>
                    <div class="border-bottom p-4 fs-5" style="width: 80%;">Finance</div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid mt-5 p-5 d-block d-lg-none"
     style="background-color: #051634">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="fs-1 fw-bold text-white text-center">Your Trusting Partner In Your Business Success</p>
            </div>
            <div class="col-12 pt-3 d-flex justify-content-center">
                <div class="row">
                    <div class="col-4">
                        <div class="slider-container">
                            <div class="slider">
                                <div class="slider-item">
                                    <img src="assets/img/landing-new/gallery/gallery1.jpeg" alt="Image 1">
                                </div>
                                <div class="slider-item">
                                    <img src="assets/img/landing-new/gallery/gallery2.jpeg" alt="Image 2">
                                </div>
                                <div class="slider-item">
                                    <img src="assets/img/landing-new/gallery/gallery3.jpeg" alt="Image 3">
                                </div>
                                <div class="slider-item">
                                    <img src="assets/img/landing-new/gallery/gallery4.jpeg" alt="Image 4">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="slider-item">
                            <img src="assets/img/landing-new/gallery/gallery2.jpeg" alt="Image 1">
                        </div>
                        <div class="slider-item">
                            <img src="assets/img/landing-new/gallery/gallery3.jpeg" alt="Image 2">
                        </div>
                        <div class="slider-item">
                            <img src="assets/img/landing-new/gallery/gallery4.jpeg" alt="Image 3">
                        </div>
                        <div class="slider-item">
                            <img src="assets/img/landing-new/gallery/gallery1.jpeg" alt="Image 4">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="slider-item">
                            <img src="assets/img/landing-new/gallery/gallery3.jpeg" alt="Image 1">
                        </div>
                        <div class="slider-item">
                            <img src="assets/img/landing-new/gallery/gallery2.jpeg" alt="Image 2">
                        </div>
                        <div class="slider-item">
                            <img src="assets/img/landing-new/gallery/gallery1.jpeg" alt="Image 3">
                        </div>
                        <div class="slider-item">
                            <img src="assets/img/landing-new/gallery/gallery4.jpeg" alt="Image 4">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-white mt-4">
                <div class="border-bottom p-4 fs-5 text-center fw-bold">HealthCare</div>
                <div class="border-bottom p-4 fs-5 text-center fw-bold">Education</div>
                <div class="border-bottom p-4 fs-5 text-center fw-bold">Technology</div>
                <div class="border-bottom p-4 fs-5 text-center fw-bold">SaaS & Web3</div>
                <div class="border-bottom p-4 fs-5 text-center fw-bold">Management</div>
                <div class="p-4 fs-5 text-center fw-bold">Finance</div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="pr-10 pt-5">
        <p class="fs-1 fw-bold text-center">Innovative IT Strategies and Solutions</p>
    </div>
    <div class="container p-5 d-flex justify-content-center align-items-center">
        <div class="row">
            <div class="col-md-4 col-12">
                <div class="rectangular-div py-5 px-4 mb-5 shadow-lg rounded-3">
                    <div class="image-container">
                        <img src="assets/img/landing-new/cloud.png" alt="Image 1">
                    </div>
                    <p class="mt-2 fs-5 fw-bold">Cloud Solution Management</p>
                    <p class="fs-6 mt-2 py-2 text-secondary">Enjoy scalable, secure, and efficient
                        cloud
                        solutions that
                        enhance your
                        operational agility and flexibility.</p>
                </div>
                <div class="rectangular-div py-5 px-4 shadow-lg rounded-3">
                    <div class="image-container">
                        <img src="assets/img/landing-new/analytics.png" alt="Image 1">
                    </div>
                    <p class="mt-2 fs-5 fw-bold">Custom Software Development</p>
                    <p class="fs-6 mt-2 py-2 text-secondary">Our bespoke development services
                        create solutions
                        tailored
                        specifically to
                        your business challenges, delivering performance and precision.
                    </p>
                </div>
            </div>
            <div class="col-md-4 col-12 mb-5 pr-10 mt-10">
                <img src="assets/img/landing-new/landing7.png" alt="Image" class="img-fluid"/>
            </div>
            <div class="col-md-4 col-12">
                <div class="rectangular-div py-5 px-4 mb-5 shadow-lg rounded-3">
                    <div class="image-container">
                        <img src="assets/img/landing-new/custom.png" alt="Image 1">
                    </div>
                    <p class="mt-2 fs-5 fw-bold">Analytics Business Intelligence</p>
                    <p class="fs-6 mt-2 py-2 text-secondary">Make data-driven decisions that propel
                        your
                        business
                        forward, with clear,
                        actionable intelligence at your fingertips.
                    </p>
                </div>
                <div class="rectangular-div py-5 px-4 shadow-lg rounded-3">
                    <div class="image-container">
                        <img src="assets/img/landing-new/machine.png" alt="Image 2">
                    </div>
                    <p class="mt-2 fs-5 fw-bold">Machine Learning Implementation</p>
                    <p class="fs-6 mt-2 py-2 text-secondary">Automate processes, enhance
                        predictions, and gain a
                        competitive edge
                        through intelligent, data-driven technologies.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 p-5" style="background-color: #F4F7FB;">
    <div class="container d-flex flex-column flex-md-row align-items-center justify-content-center">
        <div class="row g-5">
            <div class="col-md-6 col-12 d-flex flex-column align-items-center align-items-md-start">
                <p class="fs-1 fw-bold text-center text-md-start">Volmint Technologies on A Forefront</p>
                <p class="fs-6 text-center text-md-start text-secondary justify-text">We deliver tailored
                    solutions that
                    drive
                    efficiency and
                    innovation, keeping you ahead in a fast-paced digital world. Discover how our forward-thinking
                    solutions are reshaping the tech landscape.
                </p>
                <div class="row mt-2">
                    <div class="col-12 col-md-2 d-flex justify-content-center justify-content-md-start">
                        <img src="assets/img/landing-new/innovative.png" alt="Image" class="mt-2 h-75 img-fluid">
                    </div>
                    <div class="col-12 col-md-10 d-flex flex-column justify-content-center">
                        <p class="fs-5 fw-bold text-center text-md-start">Innovative Tech Leader</p>
                        <p class="fs-6 text-center text-md-start text-secondary">Partner with industry leaders
                            to
                            transform bold ideas
                            into cutting-edge technologies and stay ahead with innovative solutions.</p>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-md-2 d-flex justify-content-center justify-content-md-start">
                        <img src="assets/img/landing-new/reliable.png" alt="Image" class="mt-2 h-75 img-fluid">
                    </div>
                    <div class="col-12 col-md-10 d-flex flex-column justify-content-center">
                        <p class="fs-5 fw-bold text-center text-md-start">Reliable Global Support</p>
                        <p class="fs-6 text-center text-md-start text-secondary">Get 24/7 global support with
                            Volmint to
                            keep your
                            technology running smoothly anywhere in the world.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-center align-items-center">
                <img src="assets/img/landing-new/landing9.jpg" alt="Image" class="img-fluid rounded-4"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 p-5 d-none d-lg-block"
     style="background-image:url('assets/img/landing-new/landing8.png');">
    <div class="container p-5">
        <div class="row d-flex justify-content-center align-items-center g-5">
            <div class="col-md-4 col-12">
                <img src="assets/img/landing-new/volmint-logo.png" alt="Image" class="img-fluid"/>
                <p class="mt-5 fs-4 fw-bold color-white text-wrap">At Volmint, we are dedicated to providing innovative
                    software
                    solutions and IT services that empower businesses.</p>
            </div>
            <div class="col-md-2 col-12"></div>
            <div class="col-md-6 col-12 color-white text-end">
                <div class="row d-flex align-items-center justify-content-center border-bottom p-2 mb-3"
                     style="width: 100%;">
                    <div class="col-12 col-md-4 text-md-end custom-large-font">
                        10 +
                    </div>
                    <div class="col-12 col-md-8 fs-6 text-center text-md-start">
                        Live Products
                    </div>
                </div>
                <div class="row d-flex align-items-center justify-content-center border-bottom p-2 mb-3"
                     style="width: 100%;">
                    <div class="col-12 col-md-4 text-md-end custom-large-font">
                        20 +
                    </div>
                    <div class="col-12 col-md-8 fs-6 text-center text-md-start">
                        Industries We Serve
                    </div>
                </div>
                <div class="row d-flex align-items-center justify-content-center border-bottom p-2"
                     style="width: 100%;">
                    <div class="col-12 col-md-4 text-md-end custom-large-font">
                        30%
                    </div>
                    <div class="col-12 col-md-8 fs-6 text-center text-md-start">
                        Increase in Productivity
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 p-5 d-block d-lg-none" style="background-color: #051634;">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <img src="assets/img/landing-new/volmint-logo.png" alt="Image" class="img-fluid"/>
                <p class="mt-4 fs-4 text-white text-wrap fw-bold ">At Volmint, we are dedicated to providing innovative
                    software
                    solutions and IT services that empower businesses.</p>
            </div>
            <div class="col-12 text-white pb-4">
                <div class="d-flex flex-column align-items-center justify-content-center border-bottom p-2 mb-3">
                    <div class="text-end custom-large-font fw-bold">
                        5 +
                    </div>
                    <div class="fs-6 text-center fw-bold">
                        Years of Experience
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center justify-content-center border-bottom p-2 mb-3">
                    <div class="text-end custom-large-font fw-bold">
                        20 +
                    </div>
                    <div class="fs-6 text-center fw-bold">
                        Industries We Serve
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center justify-content-center border-bottom p-2">
                    <div class="text-end custom-large-font fw-bold">
                        30%
                    </div>
                    <div class="fs-6 text-center fw-bold">
                        Increase in Productivity
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="projects">
    <div class="container-fluid p-5" style="background-color: #5236FF;">
        <div class="row justify-content-center">
            <!-- Card for each logo -->
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/subharti-logo.png" alt="Subharti Logo"
                             class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/Vivekananda-Global-University-Logo.png"
                             alt="Vivekananda Global University Logo" class="img-fluid"
                             style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/arni.png" alt="SLDC Logo" class="img-fluid"
                             style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/sldc.png" alt="SLDC Logo" class="img-fluid"
                             style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <!-- Card for each logo -->
            <div class="col-6 col-md-4 col-lg-4 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/Educationmitra.webp" alt="Education Mitra Logo"
                             class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-4 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/edutantra_Logo.png" alt="Edutantra Logo"
                             class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-4 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/Gyan-Vihar-University.png" alt="SGVU Logo"
                             class="img-fluid"
                             style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <!-- Card for each logo -->
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/draxico-logo.gif" alt="Dr Axico Logo"
                             class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/ms-intitute.png" alt="MS Logo" class="img-fluid"
                             style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/nation-live.jpg" alt="Nation News Logo"
                             class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <div class="card shadow-sm">
                    <div class="card-body d-flex justify-content-center align-items-center" style="height: 150px;">
                        <img src="assets/img/landing-new/company-logos/axicoayurveda.jpg" alt="Nation News Logo"
                             class="img-fluid" style="max-height: 100px; background-color: white; padding: 10px;"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid mt-5 p-5">
    <div class="container">
        <div class="row">
            <p class="fs-1 fw-bold text-center">Innovation in Action: Our Previous Work</p>
            <p class="fs-5 text-center text-wrap text-secondary">See how we turn challenges into successes with
                our innovative solutions.</p>
        </div>

        <div class="row py-4 g-5 d-flex justify-content-center">
            <div class="col-md-2 col-12 mb-3">
                <div
                    class="custom-div fs-6 py-3 fw-bold"
                    [ngClass]="{'active': activeCategory === 'all'}"
                    (click)="changeImages('all')">
                    All
                </div>
            </div>
            <div class="col-md-2 col-12 mb-3">
                <div
                    class="custom-div fs-6 py-3 fw-bold"
                    [ngClass]="{'active': activeCategory === 'mobile'}"
                    (click)="changeImages('mobile')">
                    Mobile App
                </div>
            </div>
            <div class="col-md-2 col-12 mb-3">
                <div
                    class="custom-div fs-6 py-3 fw-bold"
                    [ngClass]="{'active': activeCategory === 'product'}"
                    (click)="changeImages('product')">
                    Product Design
                </div>
            </div>
            <div class="col-md-2 col-12 mb-3">
                <div
                    class="custom-div fs-6 py-3 fw-bold"
                    [ngClass]="{'active': activeCategory === 'web'}"
                    (click)="changeImages('web')">
                    Web Design
                </div>
            </div>
        </div>

        <div class="row g-5">
            <div class="col-md-6 col-12">
                <img [src]="image1Src" alt="Image 1" class="img-fluid rounded-3 h"/>
            </div>
            <div class="col-md-6 col-12">
                <img [src]="image2Src" alt="Image 2" class="img-fluid rounded-3"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <!--    <div class="container">-->
    <!--        <p class="fs-1 fw-bold text-center">Customers rated high Volmint Services</p>-->
    <!--        <p class="fs-6 text-center text-secondary">Volmint delivers exceptional, intuitive support across all-->
    <!--            channels, understanding and meeting customer needs from the start.</p>-->
    <!--        <div class="row pt-3">-->
    <!--            &lt;!&ndash;            <img src="assets/img/landing-new/landing12.png" alt="Image" class="img-fluid"/>&ndash;&gt;-->
    <!--            <div id="google-rating">-->
    <!--                <span style="font-size: 24px; color: gold;">★★★★☆</span>-->
    <!--                <span style="font-size: 18px;">4.9 based on 100 reviews</span>-->
    <!--                <p>-->
    <!--                    <a href="https://g.co/kgs/vfE2WFE" target="_blank">Read our reviews on Google</a>-->
    <!--                </p>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->

    <div class="container">
        <p class="fs-1 fw-bold text-center">Customers Rate Volmint Services Highly</p>
        <p class="fs-5 text-center text-muted">At Volmint, we pride ourselves on delivering exceptional and intuitive
            support across all channels, ensuring we understand and meet our customers' needs from the very start.</p>
        <div class="row pt-3 justify-content-center">
            <div id="google-rating" class="rating-card p-4 text-center">
                <div class="rating-stars">
                    <span style="font-size: 48px; color: gold;">★★★★☆</span>
                </div>
                <div class="rating-details">
                    <span class="rating-number" style="font-size: 24px;">4.9</span>
                    <span style="font-size: 18px;"> based on 100 reviews</span>
                </div>
                <p class="mt-3">
                    <a href="https://g.co/kgs/vfE2WFE" target="_blank" class="btn btn-primary">Read Our Reviews on
                        Google</a>
                </p>
            </div>
        </div>
    </div>


</div>
<div class="container-fluid p-5 d-none d-lg-block" style="background-color: #5236FF;">
    <div class="container">
        <div class="row g-4">
            <div class="col-md-3 col-12 d-flex justify-content-center">
                <div class="card d-flex justify-content-between customer-card px-2">
                    <div class="row">
                        <div class="col-6 text-start">
                            <img src="assets/img/landing-new/clutch-logo.png" alt="Image" class="h-75 w-100"/>
                        </div>
                        <div class="col-6 text-end mt-4">
                            <p class="fs-4 fw-bold">5/5</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 d-flex justify-content-center">
                <div class="card d-flex justify-content-between customer-card px-2">
                    <div class="row">
                        <div class="col-6 text-start mt-3">
                            <img src="assets/img/landing-new/google-logo.png" alt="Image" class="h-75 w-75"/>
                        </div>
                        <div class="col-6 text-end mt-4">
                            <p class="fs-4 fw-bold">4.9/5</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 d-flex justify-content-center">
                <div class="card d-flex justify-content-between customer-card px-2">
                    <div class="row">
                        <div class="col-6 text-start mt-4">
                            <img src="assets/img/landing-new/gartner-logo.png" alt="Image" class="h-100 w-100"/>
                        </div>
                        <div class="col-6 text-end mt-4">
                            <p class="fs-4 fw-bold">5/5</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-12 d-flex justify-content-center">
                <div class="card d-flex justify-content-between customer-card px-2">
                    <div class="row">
                        <div class="col-6 text-start mt-4">
                            <img src="assets/img/landing-new/g2-logo.png" alt="Image" class="h-75 w-50"/>
                        </div>
                        <div class="col-6 text-end mt-4">
                            <p class="fs-4 fw-bold">5/5</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="clients">
    <div class="container-fluid mt-5 p-5">
        <div class="container">
            <div class="row text-center text-md-start">
                <div class="col-12 text-center fs-1 fw-bold">
                    Perspectives That Matter
                </div>
            </div>
            <div id="slider-container" class="position-relative overflow-hidden p-4 d-none d-lg-block">
                <div id="slider" class="d-flex">
                    <div class="slide d-flex" *ngFor="let slide of slides">
                        <div class="row mt-4 g-5 justify-content-center">
                            <div class="col-md-4 col-12" *ngFor="let reviewer of slide">
                                <div class="card p-4 text-center">
                                    <div class="row">
                                        <div class="col-8 text-start">
                                            <img src="assets/img/landing-new/clients-part/quote.png" alt="Image"
                                                 class="img-fluid"/>
                                            <p class="fs-6 fw-bold whitespace-nowrap">{{ reviewer.name }}</p>
                                        </div>
                                        <div class="col-4 text-end">
                                            <img src="{{reviewer.image}}" alt="Image" class="img-fluid h-100"/>
                                        </div>
                                    </div>
                                    <p class="fs-6 text-wrap mt-3 text-secondary">{{ reviewer.review }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slider-indicators position-absolute bottom-0 start-50 translate-middle-x d-flex">
        <span class="indicator" *ngFor="let slide of slides; let i = index"
              (click)="currentSlide = i; updateSlider()"
              [class.active]="i === currentSlide"></span>
                </div>
            </div>
            <div class="col-12 d-block d-lg-none" *ngFor="let reviewer of reviews">
                <div class="card p-4 text-center my-3">
                    <div class="row">
                        <div class="col-8 text-start">
                            <img src="assets/img/landing-new/clients-part/quote.png" alt="Image"
                                 class="img-fluid"/>
                            <p class="fs-6 fw-bold whitespace-nowrap">{{ reviewer.name }}</p>
                        </div>
                        <div class="col-4 text-end">
                            <img src="{{reviewer.image}}" alt="Image" class="img-fluid h-100"/>
                        </div>
                    </div>
                    <p class="fs-6 text-wrap mt-3 text-secondary justify-text">{{ reviewer.review }}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blogs mb-5">
    <div class="container-fluid mt-5 p-md-3">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-6">
                    <p class="fs-1 fw-bold text-center text-md-start">Blogs and News</p>
                </div>
                <div class="col-md-6 text-center text-md-end">
                    <button class="bg-color-blue px-5 py-3 border-0 fs-6 color-white hover-effect fw-bold"
                            (click)="goTo('/blog')">View All <i
                        class="fa fa-arrow-right"
                        aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div class="row g-5 d-none d-lg-flex">
                <div class="col-md-4 col-12 position-relative card-hover mb-5" *ngFor="let blog of blogs.slice(1,4)">
                    <a (click)="goTo('blog-details/'+ blog?.url)" style="cursor: pointer;"><img
                        src="{{blog.blog_banner.url}}" alt="image"></a>
                    <div class="card-overlay">
                        <div class="card" style="cursor:pointer;">
                            <div class="card-body">
                                <p class="card-title fs-6 fw-bold"
                                   (click)="goTo('blog-details/'+ blog?.url)">{{ truncateText(blog.heading, 4) }}</p>
                                <p class="card-text text-secondary"
                                   (click)="goTo('blog-details/'+ blog?.url)">{{ truncateText(blog.short_description, 20) }}</p>
                                <hr/>
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-center">
                                        <p class="fw-bold text-nowrap color-primary-blue fw-bold"
                                           (click)="goTo('blog-details/'+ blog?.url)">Read more <i
                                            class="fa-solid fa-arrow-right"></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-lg-none d-block">
                <div class="col-md-4 mb-4 w-100" *ngFor="let blog of blogs.slice(1,4)">
                    <div class="card">
                        <a (click)="goTo('blog-details/'+ blog?.url)" style="cursor: pointer;"><img
                            src="{{blog.blog_banner.url}}" alt="image"></a>
                        <div class="card-body">
                            <p class="card-title fs-6 fw-bold"
                               (click)="goTo('blog-details/'+ blog?.url)">{{ blog.heading }}</p>
                            <p class="card-text text-secondary"
                               (click)="goTo('blog-details/'+ blog?.url)">{{ blog.short_description }}</p>
                            <div class="text-center">
                                <button class="bg-color-blue border-0 px-4 py-2 color-white fw-bold"
                                        (click)="goTo('blog-details/'+ blog?.url)">Read More <i
                                    class="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid mt-5 pt-md-5">
    <div class="container mt-5 pt-md-5">
        <p class="fs-1 fw-bold text-center mb-4 pt-md-5">Frequently Asked Questions</p>
        <div class="accordion" id="faqAccordion">
            <div class="accordion-item shadow-lg border-0">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span class="fs-6 fw-bold" style="color: #5236FF;"> <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What services does Volmint Technologies offer?</span>
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                     data-bs-parent="#faqAccordion">
                    <div class="accordion-body fs-6 text-secondary justify-text">
                        Volmint Technologies is one of the most reliable giant IT hubs for various tech solutions. It
                        offers services including website development, website design, market research, customized
                        software, etc. Volmint’s cutting-edge solutions transformed business and automated internal
                        processes to minimize human efforts and errors. Volmint has customized solutions for any kind of
                        small, MSME, or large business related to any field, such as edtech, finance, etc.
                    </div>
                </div>
            </div>
            <div class="accordion-item shadow-lg border-0">
                <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #5236FF;">
                            <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What products or software do Volmint Technologies offer?</span>
                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                     data-bs-parent="#faqAccordion">
                    <div class="accordion-body fs-6 text-secondary justify-text">
                        Being one of the most reliable IT software companies, Volmint has different kinds of products
                        for every business. The company offers university management system, college group management
                        system, college management system, learning management system, enroll- admission management
                        system, e-clinic software, student management system, and ITR management software.
                    </div>
                </div>
            </div>
            <div class="accordion-item shadow-lg border-0">
                <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span class="fs-6 fw-bold" style="color: #5236FF;">
                            <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;Does Volmint provide customized solutions?</span>
                    </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                     data-bs-parent="#faqAccordion">
                    <div class="accordion-body fs-6 text-secondary justify-text">
                        Absolutely, being the most preferred among the many is not ‘luck by chance’; it is the
                        dedication and hard work of the well-experienced team that delivered not only software but
                        satisfaction to the clients. Our motto is client gratification, which motivates us to bring the
                        most suitable solution to their table. We customize our products to align with customer needs.
                    </div>
                </div>
            </div>
            <div class="accordion-item shadow-lg border-0">
                <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <span class="fs-6 fw-bold" style="color: #5236FF;">
                            <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What is Volmint’s UMS?</span>
                    </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                     data-bs-parent="#faqAccordion">
                    <div class="accordion-body fs-6 text-secondary justify-text">
                        University Management Systems is one of the best-known and most-buyable products by Volmint
                        Technologies. Through the software, the universities can manage and control internal operations
                        such as student enrollment, fee structure, accounts, and other processes with ease, which helps
                        to decrease manpower and costs.
                    </div>
                </div>
            </div>
            <div class="accordion-item shadow-lg border-0">
                <h2 class="accordion-header" id="headingFive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Is Enroll a Volmint’s product?</span>
                    </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                     data-bs-parent="#faqAccordion">
                    <div class="accordion-body fs-6 text-secondary justify-text">
                        Yes, Enroll is designed and owned by Volmint Technologies. The product automates the student
                        admission management process. It has various features, such as multi-university management,
                        documentation management, multi-counselors and users management, effective lead management, etc.
                        One can manage and control various student admission phases under one roof, avoiding any kind of
                        miscommunication.
                    </div>
                </div>
            </div>
            <div class="accordion-item shadow-lg border-0">
                <h2 class="accordion-header" id="headingSix">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;How do Volmint Technologies Work?</span>
                    </button>
                </h2>
                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                     data-bs-parent="#faqAccordion">
                    <div class="accordion-body fs-6 text-secondary justify-text">
                        Deep market research involves understanding customer needs and desires to provide the best
                        possible solution. It takes time and effort to complete. Designing the right software requires
                        understanding the process and how to do it. Maintenance is crucial for maintaining the software
                        system's functionality. Analyzing software performance and usage post-development is essential
                        for ensuring its longevity. Our models use the latest technology and are robust and advanced.
                    </div>
                </div>
            </div>
            <div class="accordion-item shadow-lg border-0">
                <h2 class="accordion-header" id="headingSeven">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        <span
                            class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What kind of support will clients get after purchasing the software?</span>
                    </button>
                </h2>
                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                     data-bs-parent="#faqAccordion">
                    <div class="accordion-body fs-6 text-secondary justify-text">
                        Volmint believes in upgrading business growth, and it is impossible without mutual
                        collaboration. Thus, we have a dedicated team of problem solvers to help our clients at any
                        stage, from installing software to becoming professionals. We will assign a dedicated manager to
                        the customers along with 24/7 chat or call support, a user manual, and video. Moreover, lifetime
                        free maintenance, training sessions, a free server, third-party tool integration, and resolution
                        in 48 hours are like perks for the clients.
                    </div>
                </div>
            </div>
            <div class="accordion-item shadow-lg border-0">
                <h2 class="accordion-header" id="headingEight">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp;What is the cost of the software?</span>
                    </button>
                </h2>
                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                     data-bs-parent="#faqAccordion">
                    <div class="accordion-body fs-6 text-secondary justify-text">
                        To know more about our software or book a free consultation, get in touch with us via email or
                        phone. We will discuss your business requirements and offer you a customized solution, along
                        with the cost. Drop us a line at support@volmint.com or call us at +91 9310828501.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 p-5" style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’s IT to the next level?
            </div>
            <div class="col-md-6 col-12 text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold hover-effect" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>


