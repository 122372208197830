import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {FooterComponent} from './components/layout/footer/footer.component';


import {FeedbackComponent} from './components/feedback/feedback.component';

import {AboutComponent} from './components/pages/about/about.component';
import {TeamComponent} from './components/pages/team/team.component';

import {ServicesComponent} from './components/pages/services/services.component';

import {BlogComponent} from './components/pages/blog/blog.component';

import {NotFoundComponent} from './components/pages/not-found/not-found.component';
import {FaqComponent} from './components/pages/faq/faq.component';

import {ContactComponent} from './components/pages/contact/contact.component';

import {ProjectsComponent} from './components/pages/projects/projects.component';

import {HomeComponent} from './components/pages/home/home.component';

import {CareerComponent} from './components/pages/career/career.component';
import {ApplyNowComponent} from './components/pages/apply-now/apply-now.component';

import {MediaComponent} from './components/pages/media/media.component';
import {HowWeWorkComponent} from './components/pages/how-we-work/how-we-work.component';
import {TermsAndConditionComponent} from './components/pages/terms-and-condition/terms-and-condition.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {AdGalleryComponent} from './components/pages/experience/ad-gallery/ad-gallery.component';
import {FunFactsComponent} from './components/pages/experience/fun-facts/fun-facts.component';
import {VolmintLogoComponent} from './components/pages/experience/volmint-logo/volmint-logo.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {GlobalService} from './shared/services/global.service';
import {AlertService} from './shared/services/alert.service';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {IdCardComponent} from './components/pages/id-card/id-card.component';
import {BlogDetailComponent} from './components/pages/blog-detail/blog-detail.component';
import {AmsComponent} from './components/pages/ams/ams.component';
import {EducationMitraComponent} from './components/pages/education-mitra/education-mitra.component';
import {CareerDetailsComponent} from './components/pages/career/career-details/career-details.component';
import {
    RefundAndCancellationPolicyComponent
} from './components/pages/refund-and-cancellation-policy/refund-and-cancellation-policy.component';
import {UmsComponent} from './components/product-list/ums/ums.component';
import {CgmsComponent} from './components/product-list/cgms/cgms.component';
import {CmsComponent} from './components/product-list/cms/cms.component';
import {LmsComponent} from './components/product-list/lms/lms.component';
import { EClinicComponent } from './components/product-list/e-clinic/e-clinic.component';
import { AcricentComponent } from './components/product-list/acricent/acricent.component';
import { ContactFormComponent } from './components/product-list/contact-form/contact-form.component';
import {ImsComponent} from './components/product-list/ims/ims.component';
import { VcallingComponent } from './components/product-list/vcalling/vcalling.component';
import {ArgusComponent} from './components/product-list/argus/argus.component';
import { EnrollComponent } from './components/product-list/enroll/enroll.component';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        FeedbackComponent,
        AboutComponent,
        TeamComponent,
        ServicesComponent,
        BlogComponent,
        NotFoundComponent,
        FaqComponent,
        ContactComponent,
        ProjectsComponent,
        HomeComponent,
        CareerComponent,
        ApplyNowComponent,
        MediaComponent,
        HowWeWorkComponent,
        TermsAndConditionComponent,
        PrivacyPolicyComponent,
        AdGalleryComponent,
        FunFactsComponent,
        VolmintLogoComponent,
        ProfileComponent,
        IdCardComponent,
        BlogDetailComponent,
        UmsComponent,
        AmsComponent,
        EducationMitraComponent,
        CareerDetailsComponent,
        RefundAndCancellationPolicyComponent,
        CgmsComponent,
        CmsComponent,
        LmsComponent,
        EClinicComponent,
        AcricentComponent,
        ContactFormComponent,
        ImsComponent,
        VcallingComponent,
        ArgusComponent,
        EnrollComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        SnotifyModule,
        ImageCropperModule,
        FormsModule,
    ],
    providers: [HtpInterceptor,
        GlobalService,
        AlertService,
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService],
    exports: [
        ContactFormComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
