<!-- Start Page Title Area-->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <p class="fs-5 color-white text-center">About Us</p>
            <p class="fs-1 color-white text-center fw-bold">Delve into Our Chronicle – Trace Our Legacy</p>
            <div class="mt-5 text-center">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="container-fluid mt-5 p-md-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="row">
                    <img src="assets/img/landing-new/about-us/about-us-one.jpg" alt="Image"
                         class="img-fluid rounded-4"/>
                </div>
                <div class="row mt-2">
                    <div class="col-5">
                        <img src="assets/img/landing-new/about-us/about-us-two.jpg" alt="Image"
                             class="img-fluid h-75 rounded-4"/>
                    </div>
                    <div class="col-7">
                        <img src="assets/img/landing-new/about-us/about-us-three.jpg" alt="Image"
                             class="img-fluid h-75 rounded-4"/>
                    </div>
                </div>
                <!--  <img src="assets/img/landing-new/about-us/about-us-1.png" alt="Image" class="img-fluid"/>-->
            </div>
            <div class="col-12 col-md-6 mt-md-0 mt-5">
                <p class="fs-2 fw-bold">Bringing your dreams to reality through innovation and technology.</p>
                <p class="fs-6 text-secondary text-wrap">A fusion of creativity and technology, making the
                    impossible possible.</p>
                <div class="my-5">
                    <hr/>
                </div>
                <div class="row mt-md-2">
                    <div class="col-2">
                        <img src="assets/img/landing-new/about-us/about-us-four.png" alt="Image" class="img-fluid"/>
                    </div>
                    <div class="col-8">
                        <p class="fs-6 fw-bold">Technology Enriched</p>
                        <p class="text-secondary justify-text"> Our solutions are equipped with advanced
                            technology, empowering and
                            inspiring users to stay ahead in the rapidly evolving technology landscape.</p>
                    </div>
                </div>
                <div class="row mt-md-3">
                    <div class="col-2">
                        <img src="assets/img/landing-new/about-us/about-us-five.png" alt="Image" class="img-fluid"/>
                    </div>
                    <div class="col-8">
                        <p class="fs-6 fw-bold">Endless Possibilities</p>
                        <p class="text-secondary justify-text">Volmint Technologies is a team of experts
                            dedicated to exploring new
                            horizons and pushing boundaries, aiming to create solutions that exceed expectations and
                            meet your needs.
                        </p>
                    </div>
                </div>
                <div class="row mt-md-3">
                    <div class="col-2">
                        <img src="assets/img/landing-new/about-us/about-us-six.png" alt="Image" class="img-fluid"/>
                    </div>
                    <div class="col-8">
                        <p class="fs-6 fw-bold">Digital Solutions</p>
                        <p class="text-secondary justify-text">Volmint Technologies provides innovative digital
                            solutions, including
                            custom software, and robust IT support, aimed at driving growth and success in the digital
                            age.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 color-purple-light py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-12">
                <div class="d-flex justify-content-center">
                    <img src="assets/img/landing-new/about-us/volmint-mission.png" alt="Image" class="img-fluid w-75">
                </div>
                <p class="fs-1 fw-bold text-center">Our <span class="primary-blue">Mission</span></p>
                <p class="justify-text text-secondary">Volmint Technologies is on a mission to empower
                    innovation through cutting-edge technology solutions,
                    driving seamless experiences, and shaping a smarter, connected world.
                </p>
            </div>
            <div class="col-md-4 col-12">
                <div class="d-flex justify-content-center">
                    <img src="assets/img/landing-new/about-us/volmint-vission.png" alt="Image" class="img-fluid w-75">
                </div>
                <p class="fs-1 fw-bold text-center">Our <span class="primary-blue">Vision</span></p>
                <p class="justify-text text-secondary">Transforming the future by pioneering tech excellence,
                    fostering global connectivity, and becoming
                    the catalyst for digital evolution, creating a world where possibilities are limitless.
                </p>
            </div>
            <div class="col-md-4 col-12 mt-5">
                <div class="d-flex justify-content-center">
                    <img src="assets/img/landing-new/about-us/who-volmint.png" alt="Image" class="img-fluid w-75">
                </div>
                <p class="fs-1 fw-bold pt-md-5 text-center">Who we <span class="primary-blue">Are?</span></p>
                <p class="justify-text text-secondary">We're a dynamic team committed to revolutionizing
                    technology solutions, fueling innovation, and
                    delivering unparalleled expertise to propel businesses into the digital forefront.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 pt-md-4">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4">
                <p class="fs-1 fw-bold">What We Do?</p>
            </div>
            <div class="col-12 col-md-8">
                <p class="text-secondary text-wrap justify-text">At Volmint Technologies, we’re committed to
                    revolutionizing
                    technology solutions, fueling innovation, and delivering unparalleled expertise to propel businesses
                    into the digital forefront. Our dedicated support team ensures technology works for you, providing
                    world-class software, offering proactive maintenance, and quick troubleshooting.
                </p>
                <p class="text-secondary text-wrap justify-text">Being one of the most preferred IT giants, we
                    provide customized
                    solutions that are relevant to your requirements for the betterment of your organization. We have
                    crafted numerous software such as University Management Systems, Enroll, College Management Systems,
                    ITR Software, and E-Clinic digital platform. The products are designed to automate the
                    organization’s internal process with ease of technology.</p>
                <p class="text-secondary text-wrap justify-text">Our team specializes in creating custom digital
                    solutions for
                    businesses, focusing on functional and forward-thinking experiences. We integrate cutting-edge
                    technology, including AI, machine learning, cloud computing, and IoT, into existing systems. Our
                    expert consultants offer strategic insights and practical advice to optimize IT infrastructure,
                    streamline operations, and achieve business goals, making us a trusted partner in digital
                    transformation.
                </p>
            </div>
        </div>
    </div>

    <div class="container-fluid mt-5">
        <div class="container p-5 color-purple-light rounded-5">
            <div class="row">
                <div class="col-12 col-md-4">
                    <p class="fs-1 fw-bold">Numbers Does Speak</p>
                </div>
                <div class="col-12 col-md-8">
                    <div class="row border color-white rounded-4 bg-white p-2">
                        <div class="col-md-3 col-12">
                            <p class="fs-1 text-center fw-bold">10+ <span class="primary-blue fs-6">Years</span></p>
                            <p class="fs-5 text-center text-secondary">Experienced Team</p>
                        </div>
                        <div class="col-md-3 col-12">
                            <p class="fs-1 text-center fw-bold">10 <span class="primary-blue">+</span></p>
                            <p class="fs-5 text-center text-secondary">Business Solutions</p>
                        </div>
                        <div class="col-md-3 col-12">
                            <p class="fs-1 text-center fw-bold">100<span class="primary-blue">%</span></p>
                            <p class="fs-5 text-center text-secondary">Satisfaction Rate</p>
                        </div>
                        <div class="col-md-3 col-12">
                            <p class="fs-1 text-center fw-bold">96<span class="primary-blue">% </span></p>
                            <p class="fs-5 text-center text-secondary">Client Satisfaction</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid mt-5">
        <div class="container color-purple-light p-5 rounded-4">
            <div class="row">
                <h1 class="primary-blue fw-bold text-center">Our Company</h1>
                <p class="fw-bolder fs-1 text-center">Why People Believe in Volmint?</p>
                <h5 class="text-secondary text-center">Contact us to begin your journey!</h5>
                <div class="col-12 d-flex justify-content-center py-5">
                    <div class="position-relative video-container">
                        <img src="assets/img/landing-new/thumbnail/volmint-thumbnail.jpg"
                             class="img-fluid rounded-4" alt="Banner Image" id="video-thumbnail">
                        <button class="play-button position-absolute" (click)="playVideo()"></button>
                        <video #videoElement class="rounded-4" controls>
                            <source src="assets/img/landing-new/volmint-intro.webm" type="video/webm">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-10">
                    <h5 class="text-secondary mt-5 text-center">
                        Volmint Technologies is a client-centric company that delivers dependable, innovative solutions,
                        pushing boundaries and exploring new technologies. Our reputation is built on more than just
                        delivering top-notch solutions; it’s grounded in the core values that drive every aspect of our
                        work. The team, comprised of industry experts, understands clients' goals, challenges, and
                        vision, ensuring practical and effective solutions.
                    </h5>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    </div>

    <section class="values">
        <div class="container-fluid mt-5 bg-black py-5">
            <div class="container">
                <p class="color-white fs-1 fw-bold text-center">The Core Values Behind Our Work</p>
                <div class="row g-5">
                    <div class="col-12 col-md-3 card-container">
                        <div class="card bg-dark-grey text-white rounded-5 p-3">
                            <div class="row">
                                <div class="col-6">
                                    <img src="assets/img/landing-new/about-us/about-us-seven.png" alt="Image"
                                         class="img-fluid"/>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="fs-5 fw-bold color-white">Efficiency</p>
                                <p class="color-white">We prioritize timely and effective project
                                    completion, optimizing
                                    every process to provide the best solutions without waiting, ensuring timely &
                                    budget-friendly outcomes.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 card-container">
                        <div class="card bg-dark-grey text-white rounded-5 p-3">
                            <div class="row">
                                <div class="col-6">
                                    <img src="assets/img/landing-new/about-us/about-us-eight.png" alt="Image"
                                         class="img-fluid"/>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="fs-5 fw-bold color-white">Accountability</p>
                                <p class="color-white">Trust is built through reliability, transparency,
                                    honesty,
                                    integrity, and dedication in our work, ensuring commitments are
                                    met.These qualities makes us trustworthy.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 card-container">
                        <div class="card bg-dark-grey text-white rounded-5 p-3">
                            <div class="row">
                                <div class="col-6">
                                    <img src="assets/img/landing-new/about-us/about-us-nine.png" alt="Image"
                                         class="img-fluid"/>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="fs-5 fw-bold color-white">Commitment</p>
                                <p class="color-white">Our mission is to ensure your success through our passion and
                                    dedication, crafting tailored solutions with utmost care and precision to turn your
                                    vision into reality.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 card-container">
                        <div class="card bg-dark-grey text-white rounded-5 p-3">
                            <div class="row">
                                <div class="col-6">
                                    <img src="assets/img/landing-new/about-us/about-us-ten.png" alt="Image"
                                         class="img-fluid"/>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="fs-5 fw-bold color-white">Team Work</p>
                                <p class="color-white">We value collaboration, combining expertise and
                                    creativity to
                                    tackle challenges and achieve excellence, fostering innovation and delivering
                                    exceptional results.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="container-fluid mt-5">
        <div class="container">
            <div class="row g-4">
                <div class="col-12 col-md-6">
                    <p class="fs-1 fw-bold">Our Vision</p>
                    <p class="text-secondary text-wrap mb-4 justify-text">Transforming the future by pioneering
                        tech excellence,
                        fostering global connectivity, and becoming the catalyst for digital evolution, creating a world
                        where possibilities are limitless.
                    </p>
                    <hr/>
                    <div class="row mt-4 gy-5">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-2">
                                    <img src="assets/img/landing-new/about-us/bulb.png" alt="Image" class="img-fluid"/>
                                </div>
                                <div class="col-8">
                                    <p class="fw-bold fs-5">Catalyzing Digital Evolution </p>
                                    <p class="text-secondary justify-text">Our solutions drive digital change,
                                        empowering businesses
                                        and individuals to adapt, thrive, and lead in the ever-evolving digital
                                        landscape.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-2">
                                    <img src="assets/img/landing-new/about-us/settings.png" alt="Image"
                                         class="img-fluid"/>
                                </div>
                                <div class="col-8">
                                    <p class="fw-bold fs-5">Empowering Limitless Possibilities</p>
                                    <p class="text-secondary justify-text">We believe in a future where
                                        creativity and innovation
                                        break through boundaries, creating endless opportunities and reshaping
                                        industries.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 text-md-end">
                    <img src="assets/img/landing-new/about-us/about-us-eleven.png" alt="Image" class="img-fluid"/>
                </div>
            </div>
        </div>

        <div class="container-fluid mt-5 py-5"
             style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
            <div class="container">
                <div class="row text-center text-md-start">
                    <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                        Ready to take your Business’s IT to the next level?
                    </div>
                    <div class="col-md-6 col-12 text-md-end">
                        <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold"
                                (click)="goTo('/contact')">
                            Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
